import React from 'react'

const ContractsImage = (
  <svg fill='#218CDA' version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 32 32" xmlSpace="preserve">
    <g id="Layer_4" className="st0" display='none'>
      <path className="st1" d="M-160.5-2.8H234v196h-394.5V-2.8z" />
    </g>
    <g id="Outline" className="st0" display='none'>
      <path className="st2" d="M4,0H28v32H4V0z" />
      <path className="st2" d="M6.7,11.7h18.6" />
      <path className="st2" d="M6.7,6.7h18.6" />
      <path className="st2" d="M6.7,4.3h18.6" />
      <path className="st2" d="M6.7,14.2h18.6" />
      <path className="st2" d="M6.7,16.8h18.6" />
      <path className="st2" d="M6.7,19.3h18.6" />
      <path className="st2" d="M6.7,21.9h18.6" />
      <path className="st2" d="M14.2,27.6h9.4" />
      <path className="st2" d="M12.4,25l-3.7,3.6" />
      <path className="st2" d="M8.7,25l3.7,3.6" />
    </g>
    <g id="Outline_Light">
      <path className="st3" d="M28,0H4v32h24L28,0L28,0z M27.5,31.5h-23v-31h23L27.5,31.5L27.5,31.5z" />
      <path className="st3" d="M6.8,11.5h18.3V12H6.8L6.8,11.5z" />
      <path className="st3" d="M6.8,6.6h18.3v0.5H6.8L6.8,6.6z" />
      <path className="st3" d="M6.8,4.3h18.3v0.5H6.8L6.8,4.3z" />
      <path className="st3" d="M6.8,14h18.3v0.5H6.8L6.8,14z" />
      <path className="st3" d="M6.8,16.5h18.3V17H6.8L6.8,16.5z" />
      <path className="st3" d="M6.8,19h18.3v0.5H6.8L6.8,19z" />
      <path className="st3" d="M6.8,21.5h18.3V22H6.8L6.8,21.5z" />
      <path className="st3" d="M14.2,27.1h9.3v0.5h-9.3V27.1z" />
      <path className="st3" d="M12.3,24.7l-1.6,1.6L9,24.7L8.7,25l1.6,1.6l-1.6,1.6L9,28.6l1.6-1.6l1.6,1.6l0.4-0.4L11,26.7l1.6-1.6
		L12.3,24.7z"/>
    </g>
    <g id="Glyph" className="st0" display='none'>
      <path className="st1" d="M28,0H4v32H28L28,0L28,0z M12.8,28.3L12,29l-1.5-1.5L9.1,29l-0.7-0.7l1.5-1.5l-1.5-1.5l0.7-0.7l1.5,1.5
		l1.5-1.5l0.7,0.7l-1.5,1.5L12.8,28.3z M23.6,28.1h-9.4v-1h9.4V28.1z M25.2,22.4H6.7v-1h18.6V22.4z M25.2,19.8H6.7v-1h18.6V19.8z
		 M25.2,17.3H6.7v-1h18.6V17.3z M25.2,14.7H6.7v-1h18.6V14.7z M25.2,12.2H6.7v-1h18.6V12.2z M25.2,7.2H6.7v-1h18.6V7.2z M25.2,4.8
		H6.7v-1h18.6V4.8z"/>
    </g>
  </svg>
)

export default ContractsImage
