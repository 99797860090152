import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoAlert } from '../InfoAlert'

const HomePageHeading = () => {
  const [showInfoAlert, setShowInfoAlert] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    const isInfoAlertViewed = localStorage.getItem('home_info_alert')
    if (isInfoAlertViewed) {
      setShowInfoAlert(false)
    }
  }, [])

  const closeInfoAlert = () => {
    localStorage.setItem('home_info_alert', 'viewed')
    setShowInfoAlert(false)
  }

  return (
    <div>
      {showInfoAlert && (
        <InfoAlert closeInfoAlert={closeInfoAlert} />
      )}
      <div className='page-heading'>
        <h1>{t('NAVBAR.WELCOME')}</h1>
      </div>
      <div className='page-subheading'>
        <p>{t('HOME.SUBHEAD_1')}</p>
        <p>{t('HOME.SUBHEAD_2')}</p>
        <p>{t('HOME.SUBHEAD_3')}</p>
      </div>
    </div>
  )
}

export default HomePageHeading
