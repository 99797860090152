import axios from 'axios'
import { getAccessToken } from './token.service'

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

export const interceptor = () => {
  api.interceptors.request.use(
    async (config: any) => {
      const accessToken = await getAccessToken()
      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error.config
      if (error.response.status === 401 && !config.sent) {
        config.sent = true
        return api(config)
      }
      return Promise.reject(error)
    }
  )
}