import React from 'react'
import ReactDOM from 'react-dom/client'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

import App from './App'
import { msalConfig } from './azureB2C/authConfig'

import './index.scss'
import { interceptor } from './services/api.service'

const msalInstance = new PublicClientApplication(msalConfig)

interceptor()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
)
