import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '../azureB2C/authConfig'

const cfg: Configuration = {auth: msalConfig.auth}

export const getAccessToken = async () => {
  const pca = new PublicClientApplication(cfg)
  const accounts = pca.getAllAccounts()
  const account = accounts[0]

  const accessTokenRequest = {
    scopes: [process.env.REACT_APP_AZ_CLIENT_ID!],
    account
  }
  try {
    const resp = await pca.acquireTokenSilent(accessTokenRequest)
    return resp?.accessToken
  } catch (err) {
    pca.logoutRedirect()
  }

}

export const logout = async () => {
  const pca = new PublicClientApplication(cfg)
  pca.logoutRedirect()
}