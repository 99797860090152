import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const QuickLinkItem = ({
  name,
  image,
  link
}: {
  name: string,
  image: any,
  link: string
}) => {
  const { t } = useTranslation()
  return (
    <Link className='services-board-item-holder' to={link}>
      <div className='image-holder'>
        {image}
      </div>
      <h3>{t(name)}</h3>
    </Link>
  )
}
