import React from 'react'
import { RepairAndInquiriesStatusEntity } from '../../../../customEntites/RepairAndInquiries.entity'

export const FilterByStatus = ({
  activeStatus,
  setActiveStatus,
  statuses
}: {
  activeStatus: number,
  setActiveStatus: any,
  statuses: RepairAndInquiriesStatusEntity[]
}) => {
  return (
    <div className='filter-by-status-wrapper'>
      <div className='status-tabs-holder'>
        <div
          onClick={() => setActiveStatus(0)}
          key={0}
          className={`status-tab-holder ${activeStatus === 0 ? 'active' : ''}`}
        >
          {localStorage.getItem('SELECTED_LNG') === 'EN' ? 'All' : 'Alle'}
        </div>
        {statuses?.map((status) => {
          return (
            <div
              onClick={() => setActiveStatus(status?.id)}
              key={status?.id}
              className={`status-tab-holder ${activeStatus === status?.id ? 'active' : ''}`}
            >
              {localStorage.getItem('SELECTED_LNG') === 'EN' ? status?.name : status?.nameDe}
            </div>
          )
        })}
      </div>
    </div>
  )
}
