import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { Buffer } from 'buffer'
import { Button } from '@mui/material'

import ImagePreview from '../../../../components/ImagePreview'
import { AmendForm } from './'
import { serviceRequestApi } from '../../../../api/serviceRequest'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import { RepairAndInquiriesStatusEntity } from '../../../../customEntites/RepairAndInquiries.entity'
import { GetServiceRequestEntity } from '../../../../entities'
import { AttachmentEntity } from '../../../../customEntites/Attachment.entity'

export const RequestContent = ({
  activeItem,
  showAmendForm,
  setShowAmendForm,
  statuses,
  setRequests,
  requests
}: {
  activeItem: GetServiceRequestEntity,
  showAmendForm: boolean,
  setShowAmendForm: any,
  statuses: RepairAndInquiriesStatusEntity[],
  setRequests: any,
  requests: GetServiceRequestEntity[]
}) => {
  const [attachments, setAttachments] = useState<AttachmentEntity[]>([])
  const [attachmentsLoading, setAttachmentsLoading] = useState(false)
  const { t } = useTranslation()

  const getServiceRequestFiles = async () => {
    setAttachmentsLoading(true)
    await serviceRequestApi.getServiceRequestFiles(activeItem?.code)
      .then((res) => {
        const attachments: AttachmentEntity[] = []
        res.forEach((file) => {
          const fileType = file.file_name.split('.').pop()
          const fileFromBuffer: AttachmentEntity = {
            file: new File([Buffer.from(file.att_datas, 'base64')], `${file.file_name}`, {
              type: fileType === 'pdf' ? 'application/pdf' : fileType === 'svg' ? 'image/svg+xml' : fileType,
            }),
            customProperties: {
              uploading: file?.uploading
            }
          }
          attachments.push(fileFromBuffer)
        })
        setAttachments(attachments)
      })
      .catch(() => {
        setAttachments([])
      })
    setAttachmentsLoading(false)
  }

  useEffect(() => {
    if (activeItem?.code) {
      (async () => {
        await getServiceRequestFiles()
      })()
    }
  }, [activeItem])

  return (
    <div className='request-content-wrapper'>
      <div className='request-progress'>
        {statuses.map((status, index) => {

          const currentStatusIndex = statuses.findIndex((object) => {
            return object.nameAims.includes(activeItem?.status)
          })

          const statusClass =
            index < currentStatusIndex
              ? ''
              : index === currentStatusIndex
                ? status.name.toLowerCase().replace(' ', '-')
                : 'disabled'

          return (
            <div key={index} className={`progress-item-wrapper ${statusClass}`}>
              <div className='progress-item-holder'>
                <div className='step'>{status.id}</div>
                <div className='label'>
                  {localStorage.getItem('SELECTED_LNG') === 'EN'
                    ? status.name
                    : status.nameDe}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='request-info'>
        <div className='info-text-item align-left'>
          <label>{t('REPAIR_AND_INQUIRIES.CONTRACT_NUMBER')}</label>
          <p>{activeItem?.contract_number?.replace('-', '/')}</p>
        </div>
        <div className='info-text-item align-left'>
          <label>{t('REPAIR_AND_INQUIRIES.SUBJECT')}</label>
          <p>{activeItem?.subject}</p>
        </div>
        <div className='info-text-item align-left'>
          <label>{t('REPAIR_AND_INQUIRIES.URGENCY')}</label>
          <p>{t(`REPAIR_AND_INQUIRIES.URGENCY_OPTIONS.${activeItem?.urgency.toLocaleUpperCase()}`)}</p>
        </div>
        <div className='info-text-item align-left'>
          <label>{t('REPAIR_AND_INQUIRIES.DESCRIPTION')}</label>
          <p>{activeItem?.description}</p>
        </div>
      </div>
      <div className='attachments'>
        {attachmentsLoading && (
          <LoadingOverlay position='absolute' />
        )}
        {!attachmentsLoading && attachments.length == 0 && (
          <div className='no-attachments-box'>
            <h2>{t('REPAIR_AND_INQUIRIES.NO_ATTACHMENTS')}</h2>
          </div>
        )}
        {!attachmentsLoading && attachments && attachments.length > 0 && (
          <>
            <ImagePreview attachments={attachments} download={true} />
          </>
        )}
      </div>

      {/*TO-DO: Check how to get feedback*/}
      {activeItem?.zFeedbackTenantAppKTU && (
        <div className='feedback'>
          <div className='info-text-item align-left'>
            <label>{t('REPAIR_AND_INQUIRIES.FEEDBACK')}</label>
            <p>{activeItem?.zFeedbackTenantAppKTU}</p>
          </div>
        </div>
      )}
      {activeItem?.user_comment?.length > 0 && (
        <div className='amendments'>
          {activeItem?.user_comment?.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            const bd = new Date(b.date)
            const ad = new Date(a.date)
            if (bd < ad) {
              return 1
            } else {
              return -1
            }
          }).map((comment: any, index: number) => {
            return (
              <div key={index} className='info-text-item align-left'>
                <label>{t('REPAIR_AND_INQUIRIES.AMENDED_ON')} {moment(comment?.date).format('D.M.Y')}</label>
                <p>{comment?.comment?.split('CreatedBy')[0]}</p>
              </div>
            )
          })}
        </div>
      )}
      {showAmendForm && (
        <div className='amend-form'>
          <AmendForm
            reportId={activeItem?.code}
            onCancel={() => setShowAmendForm(false)}
            getServiceRequestFiles={getServiceRequestFiles}
            setRequests={setRequests}
            requests={requests}
          />
        </div>
      )}
      {!showAmendForm && (
        <div className='amend-btn-holder'>
          <Button onClick={() => setShowAmendForm(true)} size='large' variant='contained' className='main-btn'>{t('REPAIR_AND_INQUIRIES.AMEND')}</Button>
        </div>
      )}
    </div>
  )
}
