import ContractsImage from '../../../assets/icons/contracts-large'
import RepairsAndInquiriesImage from '../../../assets/icons/repairs-and-inquiries-large'

export const homepageItems: {image: any ,name: string, link: string}[] = [
  {
    image: ContractsImage,
    name: 'CONTRACTS',
    link: '/contracts'
  },
  {
    image: RepairsAndInquiriesImage,
    name: 'REPAIRS_AND_INQUIRIES',
    link: '/repairs-and-inquiries'
  }
]