import {
  CreateServiceRequestDto,
  CreateServiceRequestEntity,
  GetServiceRequestEntity,
  GetServiceRequestFilesEntity,
  ServiceRequestCategoryEntity,
  ServiceRequestUrgencyEntity,

} from '../../entities'
import { api } from '../../services/api.service'

class ServiceRequestApi {
  async createServiceRequest(serviceRequest: CreateServiceRequestDto, attachments: File[]): Promise<CreateServiceRequestEntity> {
    const apiCall = await api.post('/api/service-request', {...serviceRequest, files: attachments}, {
      timeout: 500000,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return apiCall.data
  }

  async getCategories(lang: string): Promise<ServiceRequestCategoryEntity[]> {
    const apiCall = await api.get(`/api/service-request/categories/lang/${lang}`)
    return apiCall.data
  }

  async getUrgencies(): Promise<ServiceRequestUrgencyEntity[]> {
    const apiCall = await api.get('/api/service-request/urgencies')
    return apiCall.data
  }

  async getServiceRequestsByContractNumber(contractNumber: string): Promise<GetServiceRequestEntity[]> {
    const apiCall = await api.get(`/api/service-request/get-requests-by-contract-number/${contractNumber}`)
    return apiCall.data
  }

  async getServiceRequestsByContractsList(contractsList: string, status: number, forceBtpRequest = false): Promise<GetServiceRequestEntity[]> {
    const apiCall = await api.get('/api/service-request/get-requests-by-contracts-list', {
      params: {
        contractsList,
        status,
        forceBtpRequest
      }
    })
    return apiCall.data
  }

  async getServiceRequestFiles(srNumber: string): Promise<GetServiceRequestFilesEntity[]> {
    const apiCall = await api.get(`/api/service-request/files/${srNumber}`)
    return apiCall.data
  }
}

export const serviceRequestApi = new ServiceRequestApi()
