import { useState, useEffect } from 'react'

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth

function useCurrentWidth() {
  const [width, setWidth] = useState(getWidth())
  const minDesktopSize = process.env.REACT_APP_MIN_DESKTOP_SIZE ? Number(process.env.REACT_APP_MIN_DESKTOP_SIZE) : 992
  useEffect(() => {
    let timeoutId: any = undefined
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width < minDesktopSize
}

export default useCurrentWidth
