import React from 'react'
import './index.scss'

const NotFound = () => {
  return (
    <div className='not-found-holder'>
      <div className='not-found-number'>
        404
      </div>
      <div className='not-found-text'>
        OOPS! PAGE NOT FOUND
      </div>
    </div>
  )
}

export default NotFound
