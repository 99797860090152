import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

import { RequestContent } from '.'
import { RepairAndInquiriesStatusEntity } from '../../../../customEntites/RepairAndInquiries.entity'
import { GetServiceRequestEntity } from '../../../../entities'

export const RequestSummary = ({
  request,
  isMobile,
  activeItem,
  setActiveItem,
  showAmendForm,
  setShowAmendForm,
  statuses,
  setRequests,
  requests
}: {
  request: GetServiceRequestEntity,
  isMobile: boolean,
  activeItem: GetServiceRequestEntity,
  setActiveItem: any,
  showAmendForm: boolean,
  setShowAmendForm: any,
  statuses: RepairAndInquiriesStatusEntity[],
  setRequests: any,
  requests: GetServiceRequestEntity[]
}) => {
  const onSelect = () => {
    setActiveItem(request)
    setShowAmendForm(false)
  }

  useEffect(() => {
    const element = document.querySelector(`.summary-wrapper.s${activeItem.code}`)
    if (element && isMobile) {
      setTimeout(() => {
        element.scrollIntoView({ block: 'start' })
        window.scrollBy(0, -80)
      }, 50)
    }
  }, [activeItem])

  const { t } = useTranslation()

  const requestStatus = statuses.find((status) => status?.nameAims?.includes(request?.status))

  return (
    <div className={`request-summary-wrapper ${request?.code === activeItem?.code ? 'active' : ''}`}>
      <div className={`summary-wrapper s${request?.code}`}  onClick={() => onSelect()}>
        <div className='summary-top-wrapper'>
          <div className={`status-chip ${requestStatus?.name.toLocaleLowerCase().replace(' ', '-')}`}>
            {localStorage.getItem('SELECTED_LNG') === 'EN' ? requestStatus?.name : requestStatus?.nameDe}
          </div>
          <p className='sr-number'>{request?.code}</p>
          {request?.urgency === 'Urgent' && (
            <p className='urgent'>
              {t('REPAIR_AND_INQUIRIES.URGENT')}
            </p>
          )}
        </div>
        <div className='summary-bottom-wrapper'>
          <h4 className='subject'>{request?.subject}</h4>
          <p className='submitted-date'>
            {t('REPAIR_AND_INQUIRIES.SUBMITTED_ON')} {moment(request?.create_date).format('D.M.Y')}
          </p>
        </div>
        <div className='icon-holder'>
          <ChevronRightIcon />
        </div>
      </div>
      {isMobile && request && activeItem && activeItem?.code === request?.code && (
        <div className='content-wrapper'>
          <RequestContent
            statuses={statuses}
            activeItem={activeItem}
            showAmendForm={showAmendForm}
            setShowAmendForm={setShowAmendForm}
            setRequests={setRequests}
            requests={requests}
          />
        </div>
      )}
    </div>
  )
}
