import { Alert, Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './index.scss'
import { contractApi } from '../../api/contract'
import { GlobalContext } from '../../App'
import LoadingOverlay from '../../components/LoadingOverlay'
import { ContextEntity } from '../../customEntites/context.entity'
import { Snackbar } from '@mui/material'
import { generateError } from '../../services/error.service'

const ActiveContractLayout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { context, setContext }: ContextEntity = useContext(GlobalContext)
  const [loading, setLoading] = useState(true)
  const [hasActiveContract, setHasActiveContract] = useState(false)
  const [hasPendingContract, setHasPendingContract] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMsg, setErrorAlertMsg] = useState('')

  useEffect(() => {
    (async () => {
      setLoading(true)
      await contractApi.getContractsByAppId()
        .then(res => {
          const activeContracts = res.filter((contract) => contract.approvalState == 'approved')
          setContext((prev: any) => ({ ...prev, contracts: { activeContracts, contracts: res } }))
          setShowErrorAlert(false)
        })
        .catch(err => {
          setShowErrorAlert(true)
          setErrorAlertMsg(generateError(err?.response?.data?.message))
        })
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    const activeContracts = context.contracts.activeContracts
    const contracts = context.contracts.contracts
    if (activeContracts?.length > 0) {
      setHasActiveContract(true)
      setHasPendingContract(false)
    }
    const hasPending = contracts?.find((contract) => contract.approvalState == 'pending')
    if (activeContracts?.length === 0 && hasPending) {
      setHasActiveContract(false)
      setHasPendingContract(true)
    }
  }, [context?.contracts])

  const isHomePage = useLocation().pathname === '/' ? true : false

  return (
    <div className='active-contract-layout-holder'>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <>
          {(!hasActiveContract || hasPendingContract) && (
            <div className={`unactive-contract-overlay ${!isHomePage ? '' : ''}`}>
              <div className='unactive-contract-overlay-content'>
                <div className={`page-heading ${hasPendingContract ? 'pending' : ''}`}>
                  {hasPendingContract ? (
                    <h1>
                      {t('ACTIVE_CONTRACT_OVERLAY.CONTRACTS_STATUS_PENDING')}
                    </h1>
                  ) : (
                    <h1>
                      {isHomePage ? t('ACTIVE_CONTRACT_OVERLAY.PLEASE_ADD_CONTRACT') : t('ACTIVE_CONTRACT_OVERLAY.OVERLAY_TITLE')}
                    </h1>
                  )}
                </div>
                {(!hasActiveContract && !hasPendingContract) && (
                  <Button
                    onClick={() => navigate('/contracts/add-contract')}
                    className='main-btn'
                    variant='contained'
                    size='large'
                  >
                    {t('ACTIVE_CONTRACT_OVERLAY.ADD_CONTRACT')}
                  </Button>
                )}
              </div>
            </div>
          )}
          <Outlet />
        </>
      )}
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={() => setShowErrorAlert(false)} anchorOrigin={{horizontal:'right', vertical:'bottom'}}>
        <Alert severity='error' sx={{ width: '100%' }} variant='filled'>
          {errorAlertMsg}  
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ActiveContractLayout
