import { UserEntity } from '../../entities'
import { api } from '../../services/api.service'

class UserApi {
  async deleteUser(azureGuid: string) {
    const apiCall = await api.delete(`/api/user/${azureGuid}`)
    return apiCall.data
  }

  async getUserProfile(): Promise<UserEntity> {
    const apiCall = await api.get('/api/user/profile')
    return apiCall.data
  }

  async changePasswordConfirmation() {
    const apiCall = await api.post('/api/user/change-password-confirmation')
    return apiCall.data
  }

  async changeEmailConfirmation() {
    const apiCall = await api.post('/api/user/change-email-confirmation')
    return apiCall.data
  }
}

export const userApi = new UserApi()