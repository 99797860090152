export const de = {
  'NAVBAR': {
    'REPAIRS_AND_INQUIRIES': 'Reparaturen & Anfragen',
    'ACCOUNT': 'Konto',
    'LOG_OUT': 'Ausloggen',
    'NEW_REQUEST': 'Neue Anfrage',
    'WELCOME': 'HAUPTMENÜ',
    'HOME': 'Hauptmenü',
    'CONTRACTS': 'Verträge',
    'ADD_CONTRACT': 'Mietvertrag hinzufügen'
  },
  'HOME': {
    'CLEAR_ALL': 'Alles löschen',
    'REPAIRS_AND_INQUIRIES': 'Reparaturen & Anfragen',
    'QUICK_LINKS': 'Schnellzugriff',
    'CONTRACTS': 'Verträge',
    'SUBHEAD_1': 'Herzlich Willkommen im Kundenportal von Aroundtown!',
    'SUBHEAD_2': 'Hier haben Sie die Möglichkeit, Serviceanfragen an Ihren Ansprechpartnern zu senden und den Status Ihrer Anfragen jederzeit nachzuverfolgen.',
    'SUBHEAD_3': '- Alles an einem Ort -',
    'AROUNDTOWN_INTRO_LINK': 'Aroundtown',
    'AROUNDTOWN_INTRO': ' ist Deutschlands größtes Unternehmen für Gewerbeflächen und zählt zu den größten Immobilienunternehmen Europas. Experten aus allen Bereichen des Immobilien-Managements betreuen das Portfolio aus Büro- und Gewerbeflächen, Einzelhandel und Shopping-Centern, Logistik- und Industriearealen. Ihr Anliegen ist uns wichtig! Seit 2004 sind wir verlässlicher Partner für unsere Mieter, die die ganzheitliche Betreuung mit fachspezifischen Ansprechpartnern schätzen.',
    'INFO': 'Info zur Beachtung',
    'INFO_TEXT': 'Verschiedene Nutzer im Mieterportal, die identische Mietvertragsnummern nutzen, können vorhandene Serviceanfragen zu diesen Mietvertragsnummern wechselseitig einsehen.'
  },
  'FOOTER': {
    'CONTACT': 'Kontakt',
    'IMPRINT': 'Impressum',
    'PRIVACY_POLICY': 'Datenschutzerklärung',
    'IMPORTANT_PHONE_NUMBERS': 'Wichtige Telefonnummern',
    'SERVICE_CENTER': 'Service Center',
    'HOMEPAGE_AROUNDTOWN': 'Homepage AROUNDTOWN',
    'HOMEPAGE_AROUNDTOWN_SERVICE': 'Homepage AROUNDTOWN-Service',
    'HOMEPAGE_SNAPDESK': 'Homepage SNAPDESK'
  },
  'REPAIR_AND_INQUIRIES': {
    'CREATE_REQUEST': 'Anfrage erstellen',
    'CURRENTLY_NO_REQUESTS': 'Sie haben derzeit keine Anfragen eingereicht',
    'URGENT': 'Dringend',
    'SUBMITTED_ON': 'Erfasst am',
    'CATEGORY': 'Kategorie',
    'SELECT_CATEGORY': 'Kategorie zur Anfrage wählen',
    'DESCRIPTION': 'Beschreibung',
    'ENTER_DESCRIPTION': 'Geben Sie hier die Beschreibung ein',
    'SUBJECT': 'Thema der Anfrage',
    'ENTER_SUBJECT': 'Geben Sie hier das Thema ein',
    'FEEDBACK': 'Feedback',
    'URGENCY': 'Dringlichkeit',
    'SELECT_URGENCY': 'Dringlichkeit wählen',
    'CONTRACT_NUMBER': 'Vertragsnummer',
    'SELECT_CONTRACT_NUMBER': 'Wählen Sie die Vertragsnummer aus',
    'BUSINESS_UNIT': 'Geschäftsbereich',
    'SELECT_BUSINESS_UNIT': 'Geschäftsbereich wählen',
    'AMEND': 'Aktualisierung',
    'SUCCESSFULLY_REQUEST': 'Ihre Anfrage wurde erfolgreich gesendet',
    'AMENDED_ON': 'Aktualisiert am',
    'AMENDMENT': 'Aktualisierung',
    'ENTER_AMENDMENT': 'Geben Sie hier Ihre Aktualisierung ein',
    'UPLOAD_ATTACHMENT': 'Anhang hochladen',
    'SUCCESSFULLY_AMENDED': 'Sie haben diese Anfrage erfolgreich geändert',
    'THANK_YOU': 'Danke!',
    'BACK_TO_REPAIRS_AND_INQUIRES': 'Zurück zu Reparaturen und Anfragen',
    'NO_ATTACHMENTS': 'Keine Anhänge zur Vorschau vorhanden',
    'URGENCY_OPTIONS': {
      'IMMEDIATE': 'Sofort',
      'URGENT': 'Dringend',
      'NORMAL': 'Normal',
      'LOW': 'Niedrig'
    },
    'SUBHEAD_1': 'Erfassen und verfolgen Sie hier Ihre Serviceanfragen.',
    'SUBHEAD_2': 'Ein vorher hinzugefügter Mietvertrag im Bereich Verträge ist erforderlich.'
  },
  'ACCOUNT': {
    'BUSINESS_NAME': 'Firmenname',
    'ADDRESS': 'Adresse',
    'CONTACT_PERSON': 'Ansprechpartner',
    'CONTACT_PERSON_EMAIL': 'Ansprechpartner E-Mail',
    'EMAIL_ADDRESS': 'E-Mail-Adresse',
    'PHONE_NUMBER': 'Telefonnummer',
    'CONTRACT_NUMBER': 'Vertragsnummer',
    'DELETE_ACCOUNT': 'Nutzerprofil löschen',
    'EDIT_PERSONAL_DATA': 'Persönliche Daten bearbeiten',
    'EDIT_PASSWORD': 'Kennwort ändern',
    'EDIT_EMAIL': 'E-Mail ändern'
  },
  'ADD_CONTRACT': {
    'ADD_CONTRACT': 'Mietvertrag hinzufügen',
    'ENTER_CONTRACT_DETAILS': 'Bitte geben Sie Ihre Vertragsdaten an.',
    'CONTRACT_NUMBER': 'Vertragsnummer',
    'ENTER_CONTRACT_NUMBER': 'Geben Sie hier Ihre Mietvertragsnummer ein',
    'CONTRACT_NUMBER_DESC': 'Sie finden Ihre Mietvertragsnummer in Ihrem Vertrag. Bitte stellen Sie sicher, dass Sie die Nummer richtig eingeben, das Format ist 0000/GW0000000.',
    'ADDRESS': 'Addresse',
    'ZIP': 'PLZ',
    'CITY': 'Stadt',
    'STREET': 'Straße',
    'STREET_NUMBER': 'Nummer',
    'SELECT_ADDRESS': 'Adresse auswählen',
    'SELECT_ZIP': 'PLZ auswählen',
    'SELECT_CITY': 'Stadt auswählen',
    'SELECT_STREET': 'Straße auswählen',
    'ENTER_STREET_NUMBER': 'Geben Sie hier Ihre Hausnummer ein',
    'NO_CONTRACT_RESPONSE': 'Wir konnten Ihre Mietvertragsnummer nicht identifizieren. Bitte versuchen Sie es erneut oder reichen Sie Ihre Vertragsnummer zur manuellen Überprüfung ein. Unsere Mitarbeiter werden Ihre Anfrage prüfen und eine Aktivierung vornehmen.',
    'SUBMIT_MANUAL_APPROVAL': 'Zur manuellen Überprüfung absenden.',
    'SUCCESSFULLY_ADDED': 'Ihre Anfrage wurde erfolgreich gesendet',
    'SUCCESSFULLY_MANUAL_ADDED': 'Ihre Registrierungsinformationen wurden zur manuellen Überprüfung versandt. Sie werden per E-Mail informiert, sobald die Überprüfung abgeschlossen ist.',
    'THANK_YOU': 'Danke!',
    'BACK_TO_REPAIRS_AND_INQUIRES': 'Zurück zu Reparaturen und Anfragen',
    'BACK_TO_CONTRACTS': 'Zurück zu Verträgen',
    'NO_CONTRACT_ADDED_YET': 'Sie haben noch keinen Vertrag hinzugefügt. Bitte klicken Sie hier, um einen Vertrag hinzuzufügen.',
    'CONTRACT_EXIST': 'Dieser Vertrag wurde bereits hinzugefügt.'
  },
  'ACTIVE_CONTRACT_OVERLAY': {
    'CONTRACTS_STATUS_PENDING': 'Ausstehend - Ihre Vertragsangaben werden derzeit geprüft',
    'OVERLAY_TITLE': 'Fügen sie bitte zuerst Ihre Vertragsdaten hinzu',
    'ADD_CONTRACT': 'Mietvertrag hinzufügen',
    'PLEASE_ADD_CONTRACT': 'Bitte fügen Sie einen Vertrag hinzu'
  },
  'LOG_OUT': {
    'LOG_OUT': 'Ausloggen',
    'ARE_YOU_SURE': 'Sind Sie sicher, dass Sie sich abmelden möchten?'
  },
  'DELETE_ACCOUNT': {
    'DELETE_ACCOUNT': 'Nutzerprofil löschen',
    'ARE_YOU_SURE': 'Sind Sie sicher, dass Sie das Nutzerprofil löschen möchten?'
  },
  'BUTTON': {
    'SUBMIT': 'Senden',
    'CANCEL': 'Zurück',
    'YES': 'Ja',
    'NO': 'Nein',
    'TRY_AGAIN': 'Bitte versuchen Sie es erneut',
    'PROCESSING': 'In Bearbeitung'
  },
  'FORM': {
    'REQUIRED_FIELD': 'Dieses Feld ist erforderlich',
    'INVALID_CONTRACT_NUMBER': 'Die Vertragsnummer ist ungültig. Bitte das im Text benannte Format beachten.'
  },
  'ALERTS': {
    'STATUS': {
      'IN_PROGRESS': 'In Bearbeitung',
      'RESOLVED': 'Abgeschlossen',
      'VERIFIED': 'Bestätigt',
      'REJECTED': 'Abgelehnt',
      'PENDING': 'Ausstehend',
      'SUBMITTED': 'Gesendet'
    },
    'CATEGORY': {
      'SERVICE_REQUEST': 'Reparaturen & Anfragen',
      'CONTRACT': 'Vertrag'
    }
  },
  'CONTRACTS': {
    'PENDING': 'Ausstehend',
    'ACTIVE': 'Aktiv',
    'INACTIVE': 'Abgelehnt',
    'ADD_CONTRACT': 'Mietvertrag hinzufügen',
    'SUBHEAD_1': 'Fügen Sie hier den betreffenden Mietvertrag hinzu, um Ihre Serviceanfragen zu erstellen.'
  },
  'CATEGORIES': {
    'ALLGEMEINEANFRAGE': 'Allgemeine Anfrage',
    'MIETVERTRAG': 'Mietvertrag',
    'KÜNDIGUNG': 'Kündigung',
    'DSLM': 'DSLM',
    'BUCHHALTUNG': 'Buchhaltung',
    'BETRIEBSKOSTEN': 'Betriebskosten',
    'MIETERHÖHUNG': 'Mieterhöhung',
    'MIETSCHULDEN': 'Mietschulden',
    'INAKTIVEMIETSCHULDEN': 'Inaktive Mietschulden',
    'ÜBERNAHME': 'Übernahme',
    'CAPEX': 'CAPEX',
    'INSTANDHALTUNG': 'Instandhaltung',
    'VERSICHERUNG': 'Versicherung'
  },
  'ERRORS': {
    'SOMETHING_WENT_WRONG': 'Etwas ist schief gelaufen! Bitte versuchen Sie es erneut!',
    'USER_NOT_FOUND': 'Der User wurde nicht gefunden',
    'GET_USER_PROFILE': 'Fehler beim Abrufen des Userprofils',
    'DELETE_USER_PROFILE': 'Fehler beim Löschen des Users',
    'ENSURE_TOKEN': 'Fehler beim generieren eines Token',
    'CREATE_SERVICE_REQUEST': 'Fehler beim Erstellen einer Serviceanfrage',
    'GET_SERVICE_REQUEST': 'Fehler beim Abrufen einer Serviceanfrage',
    'GET_CONTRACTS': 'Fehler beim Abrufen von Verträgen',
    'CREATE_AMENDMENT': 'Fehler beim Erstellen einer Änderung',
    'GET_SERVICE_REQUEST_CATEGORIES': 'Fehler beim Abrufen der Kategorien zu Serviceanfragen',
    'SEND_ATTACHMENT': 'Fehler beim Übermitteln von Anlagen',
    'GET_ATTACHMENT': 'Fehler beim Abrufen von Anlagen',
    'GET_ADDRESS': 'Fehler beim Abrufen von Adressen',
    'INVALID_CONTRACT': 'Wir konnten Ihre Mietvertragsnummer nicht identifizieren. Bitte versuchen Sie es erneut oder reichen Sie Ihre Vertragsnummer zur manuellen Überprüfung ein. Unsere Mitarbeiter werden Ihre Anfrage prüfen und eine Aktivierung vornehmen.',
    'VERIFY_CONTRACT' :'Fehler beim Verifizieren des Vertrages',
    'MANUAL_CONTRACT_VALIDATION': 'Fehler bei der manuellen Vertragsprüfung',
    'TOKEN_GRAPH_API': 'Fehler beim Versuch einen Sicherheitsschlüssel für GraphAPI abzurufen'
  }
}