import React from 'react'
import { QuickLinks } from './components'

import './index.scss'

export const Home = () => {

  return (
    <div>
      <QuickLinks />
    </div>
  )
}
