import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Snackbar } from '@mui/material'
import { Alert, Button } from '@mui/material'

import { CustomTextarea, CustomUplaodInput } from '../../../../components/FormComponets'
import ImagePreview from '../../../../components/ImagePreview'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import { amendmentApi } from '../../../../api/amendment'
import { GetServiceRequestEntity } from '../../../../entities'
import { AttachmentEntity } from '../../../../customEntites/Attachment.entity'
import { generateError } from '../../../../services/error.service'

export const AmendForm = ({
  onCancel,
  reportId,
  getServiceRequestFiles,
  setRequests,
  requests
}: {
  onCancel: any,
  getServiceRequestFiles: any,
  reportId: string,
  setRequests: any,
  requests: GetServiceRequestEntity[]
}) => {
  const [attachments, setAttachments] = useState<AttachmentEntity[]>([])
  const [submitted, setSubmitted] = useState(false)
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)

  const [showAlert, setShowAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const { t } = useTranslation()

  const AmendSchema = Yup.object().shape({
    amendment: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`)
  })
  const formik = useFormik({
    initialValues: {
      amendment: ''
    },
    onSubmit: values => {
      setShowLoadingOverlay(true)
      setAlertMsg('');
      (async () => {
        const attachmentsFiles = attachments.map((attachment) => attachment.file)
        await amendmentApi.createAmendment({ ...values, reportId: reportId.toString() }, attachmentsFiles)
          .then(() => {
            setAttachments([])
            setAlertMsg(`${t('REPAIR_AND_INQUIRIES.SUCCESSFULLY_AMENDED')}`)
            setIsSuccess(true)
            if (attachments.length > 0) {
              (async () => {
                await getServiceRequestFiles()
              })()
            }
            const newRequests = requests?.map((request) => {
              if (request.code == reportId) {
                request?.user_comment?.push({
                  comment: values?.amendment,
                  date: new Date().toISOString(),
                  sr_number: request.code
                })
              }
              return request
            })
            setRequests(newRequests)
            values.amendment = ''
          })
          .catch((err) => {
            setIsSuccess(false)
            setAlertMsg(generateError(err?.response?.data?.message))
          })
        setShowLoadingOverlay(false)
        setShowAlert(true)
      })()
    },
    validationSchema: AmendSchema
  })
  useEffect(() => {
    if (formik.isSubmitting) {
      setSubmitted(true)
    }
  }, [formik])

  return (
    <div>
      {showLoadingOverlay && (
        <LoadingOverlay />
      )}
      <form aria-autocomplete='none' autoComplete='off' className='form-center' onSubmit={formik.handleSubmit}>
        <CustomTextarea
          label={`${t('REPAIR_AND_INQUIRIES.AMENDMENT')}*`}
          name="amendment"
          onChange={formik.handleChange}
          value={formik.values.amendment}
          placeholder={`${t('REPAIR_AND_INQUIRIES.ENTER_AMENDMENT')}*`}
          error={submitted ? formik.errors.amendment : undefined}
        />
        <ImagePreview
          attachments={attachments}
          setAttachments={setAttachments}
          uploader={
            <CustomUplaodInput
              supportedFileTypes='(pdf / jpg / jpeg / png)'
              setAttachments={setAttachments}
              label={`${t('REPAIR_AND_INQUIRIES.UPLOAD_ATTACHMENT')}`}
            />
          }
          remove={true}
        />
        <div className='buttons-holder center'>
          <Button type='submit' className='main-btn' variant='contained' size='large'>{t('BUTTON.SUBMIT')}</Button>
          <Button onClick={onCancel} className='secondary-btn' variant='contained' size='large'>{t('BUTTON.CANCEL')}</Button>
        </div>
      </form>
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)} anchorOrigin={{horizontal:'right', vertical:'bottom'}}>
        <Alert severity={isSuccess ? 'success' : 'error'} sx={{ width: '100%' }} variant='filled'>
          {alertMsg}  
        </Alert>
      </Snackbar>
    </div>
  )
}
