import { AddContractDto, AddContractEntity, ContractEntity } from '../../entities'
import { api } from '../../services/api.service'

class ContractApi {
  async addContract(contractData: AddContractDto): Promise<AddContractEntity> {
    const apiCall = await api.post('/api/contract/add-contract', contractData)
    return apiCall.data
  }
  async manualApproval(contractData: AddContractDto): Promise<AddContractEntity> {
    const apiCall = await api.post('/api/contract/manual-approval', contractData)
    return apiCall.data
  }
  async getContractsByAppId(): Promise<ContractEntity[]> {
    const apiCall = await api.get('/api/contract/contracts-by-app-id')
    return apiCall.data
  }
}

export const contractApi = new ContractApi()
