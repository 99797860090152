import React from 'react'
import { useTranslation } from 'react-i18next'

import { ContractEntity } from '../../../../entities'


export const ContractListItem = ({ contract }: { contract: ContractEntity }) => {
  const { t } = useTranslation()
  const checkContractStatus = (status: string) => {
    switch (status) {
    case 'approved':
      return 'active'
      break
    case 'pending':
      return 'pending'
      break
    case 'denied':
      return 'inactive'
      break
    default:
      return 'inactive'
    }
  }
  const status = checkContractStatus(contract.approvalState)
  return (
    <div className='contract-list-item'>
      <div className='contract-list-item-head'>
        <div className={`contract-status ${status}`}>
          {t(`CONTRACTS.${status.toLocaleUpperCase()}`).toLocaleUpperCase()}
        </div>
        <p className='contract-list-item-number'>{contract?.contractNumber?.replace('-', '/')}</p>
      </div>
      <p className='contract-list-item-address'>{contract.address}</p>
    </div>
  )
}