import { XCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import pdfImg from '../../assets/pdf-img.png'
import photoImg from '../../assets/photo-img.png'
import './index.scss'
import { AttachmentEntity } from '../../customEntites/Attachment.entity'
import LoadingOverlay from '../LoadingOverlay'

const ImagePreview = ({
  attachments,
  uploader,
  download,
  remove,
  setAttachments
}: {
  attachments: AttachmentEntity[],
  uploader?: any,
  download?: boolean|undefined,
  remove?: boolean|undefined,
  setAttachments?: any
}) => {
  const downloadFile = (file: File) => {
    const url = window.URL.createObjectURL(file)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.setAttribute('style', 'display: none;')
    a.href = url
    a.download = file?.name
    a.click()
    window.URL.revokeObjectURL(url)
  }
  const onRemoveAttachment = (index: number) => {
    const newAttachments = [...attachments]
    newAttachments.splice(index, 1)
    setAttachments(newAttachments)
  }
  return (
    <div className='uploaded-images-holder'>
      {attachments.map((file: AttachmentEntity, index: number) => {
        const isDownload = download && !file?.customProperties?.uploading
        return (
          <div
            key={index}
            className={`uploaded-image-holder ${isDownload ? 'download' : ''}`}
            onClick={() => {
              if (isDownload) {
                downloadFile(file.file)
              }
            }}
          >
            {file?.customProperties?.uploading && (
              <LoadingOverlay uploading={true} position='absolute' />
            )}
            {remove && (
              <button
                type='button'
                onClick={() => onRemoveAttachment(index)}
                className='delete-attachment-button'>
                <XCircleIcon />
              </button>
            )}
            <img src={file.file.type === 'application/pdf' ? pdfImg : file?.customProperties?.uploading ? photoImg : URL.createObjectURL(file.file)} />
          </div>
        )
      })}
      {uploader && uploader}
    </div>
  )
}

export default ImagePreview
