import React from 'react'
import HomePageHeading from '../../../../pages/dashboard/Home/components/PageHeading'
import ContractsPageHeading from '../../../../pages/dashboard/Contracts/components/PageHeading'
import RepairAndInquiriesPageHeading from '../../../../pages/dashboard/RepairAndInquiries/components/PageHeading'
import AccountPageHeading from '../../../../pages/dashboard/Profile/components/PageHeading'

export const PageHeading = ({
  page
}: {
  page: string
}) => {

  const generatePageHeading = () => {
    switch(page) {
    case 'home':
      return (
        <HomePageHeading />
      )
      break
    case 'contracts':
      return  (
        <ContractsPageHeading />
      )
      break
    case 'repairs_and_inquiries':
      return (
        <RepairAndInquiriesPageHeading />
      )
      break
    case 'account':
      return <AccountPageHeading />
      break
    default:
      return (
        <HomePageHeading />
      )
    }
  }
  return generatePageHeading()
}
