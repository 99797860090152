import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Alert, Button } from '@mui/material'
import { Snackbar } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { contractApi } from '../../../api/contract'
import { CustomInput } from '../../../components/FormComponets'
import Modal from '../../../components/Modal'
import LoadingOverlay from '../../../components/LoadingOverlay'
import './index.scss'
import { ContextEntity } from '../../../customEntites/context.entity'
import { GlobalContext } from '../../../App'
import { generateError } from '../../../services/error.service'

export const AddContract = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [submitted, setSubmitted] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [manualApprovalSuccessModal, setManualApprovalSuccessModal] = useState(false)
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMsg, setErrorAlertMsg] = useState('')
  const [submitButtonState, setSubmitButtonState] = useState('')
  const { context, setContext }: ContextEntity = useContext(GlobalContext)

  useEffect(() => {
    (async () => {
      setShowLoadingOverlay(true)
      if (context?.contracts?.contracts?.length === 0) {
        await contractApi.getContractsByAppId()
          .then(res => {
            const activeContracts = res.filter((contract) => contract.approvalState == 'approved')
            setContext((prev: any) => ({ ...prev, contracts: { activeContracts, contracts: res } }))
            setShowErrorAlert(false)
          })
          .catch(err => {
            setShowErrorAlert(true)
            setErrorAlertMsg(generateError(err?.response?.data?.message))
          })
      }
      setShowLoadingOverlay(false)
    })()
  }, [])


  const handleSubmitButtonState = (isSubmit: string) => {
    setSubmitButtonState(isSubmit)
  }

  const AddContractSchema = Yup.object().shape({
    contractNumber: Yup.string().matches(/^([0-9]{4})(\/)\w+$/, `${t('FORM.INVALID_CONTRACT_NUMBER')}`).required(`${t('FORM.REQUIRED_FIELD')}`),
    zip: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`),
    city: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`),
    street: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`),
    streetNumber: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`)
  })

  const formik = useFormik({
    initialValues: {
      contractNumber: '',
      zip: '',
      city: '',
      street: '',
      streetNumber: ''
    },
    onSubmit: () => {
      setShowLoadingOverlay(true)
      const existedContract = context?.contracts?.contracts?.find((contract) => contract.contractNumber.replace('-', '/') === formik.values.contractNumber)
      if (existedContract) {
        setErrorAlertMsg(`${t('ADD_CONTRACT.CONTRACT_EXIST')}`)
        setShowLoadingOverlay(false)
        setShowErrorAlert(true)
        return
      }
      if (submitButtonState === 'primary') {
        (async () => {
          await contractApi.addContract(
            {
              contractNumber: formik.values.contractNumber,
              address: `${formik.values.street} ${formik.values.streetNumber}, ${formik.values.zip} ${formik.values.city}`
            }
          )
            .then(() => {
              setShowErrorAlert(false)
              setShowSuccessModal(true)
            })
            .catch((err) => {
              setErrorAlertMsg(generateError(err?.response?.data?.message))
              setShowErrorAlert(true)
            })
          setShowLoadingOverlay(false)
        })()

      } else if (submitButtonState === 'secondary') {
        (async () => {
          await contractApi.manualApproval(
            {
              contractNumber: formik.values.contractNumber,
              address: `${formik.values.street} ${formik.values.streetNumber}, ${formik.values.zip} ${formik.values.city}`
            }
          )
            .then(() => {
              setManualApprovalSuccessModal(true)
              setShowErrorAlert(false)
            })
            .catch((err) => {
              setErrorAlertMsg(generateError(err?.response?.data?.message))
              setShowErrorAlert(true)
            })
          setShowLoadingOverlay(false)
        })()
      }
    },
    validationSchema: AddContractSchema
  })

  useEffect(() => {
    if (formik.isSubmitting) {
      setSubmitted(true)
    }
  }, [formik])

  const onSuccessModalCancel = () => {
    setShowSuccessModal(false)
    navigate('/contracts')
  }

  return (
    <div className='add-contract-page-wrapper'>
      {showLoadingOverlay && (
        <LoadingOverlay />
      )}
      {showSuccessModal && (
        <Modal
          head={`${t('ADD_CONTRACT.THANK_YOU')}`}
          text={`${t('ADD_CONTRACT.SUCCESSFULLY_ADDED')}`}
          cancelText={`${t('ADD_CONTRACT.BACK_TO_CONTRACTS')}`}
          onCancel={() => onSuccessModalCancel()}
          onConfirm={null}
          confirmText={null}
        />
      )}
      {manualApprovalSuccessModal && (
        <Modal
          head={`${t('ADD_CONTRACT.THANK_YOU')}`}
          text={`${t('ADD_CONTRACT.SUCCESSFULLY_MANUAL_ADDED')}`}
          cancelText={null}
          onCancel={() => onSuccessModalCancel()}
          onConfirm={null}
          confirmText={null}
        />
      )}
      <div className='subpage-head-content'>
        <h3>{t('ADD_CONTRACT.ADD_CONTRACT')}</h3>
        <p>{t('ADD_CONTRACT.ENTER_CONTRACT_DETAILS')}</p>
      </div>
      <form aria-autocomplete='none'  autoComplete='off' className='form-center'>
        <CustomInput
          label={`${t('ADD_CONTRACT.CONTRACT_NUMBER')}*`}
          name="contractNumber"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.contractNumber}
          placeholder={`${t('ADD_CONTRACT.ENTER_CONTRACT_NUMBER')}*`}
          instruction={`${t('ADD_CONTRACT.CONTRACT_NUMBER_DESC')}`}
          error={submitted ? formik.errors.contractNumber : undefined}
        />
        <div className='address-inputs-wrapper'>
          <div className='address-pair-inputs-holder'>
            <CustomInput
              containerStyle={{ width: '63px', flexShrink: 0 }}
              inputStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
              label={`${t('ADD_CONTRACT.ZIP')}*`}
              name="zip"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.zip}
              error={submitted ? formik.errors.zip : undefined}
            />
            <CustomInput
              containerStyle={{ width: '100%' }}
              inputStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
              label={`${t('ADD_CONTRACT.CITY')}*`}
              name="city"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.city}
              error={submitted ? formik.errors.city : undefined}
            />
          </div>
          <div className='address-pair-inputs-holder'>
            <CustomInput
              containerStyle={{ width: '100%' }}
              inputStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
              label={`${t('ADD_CONTRACT.STREET')}*`}
              name="street"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.street}
              error={submitted ? formik.errors.street : undefined}
            />
            <CustomInput
              containerStyle={{ width: '63px', flexShrink: 0 }}
              inputStyle={{ paddingLeft: '8px', paddingRight: '8px' }}
              label={`${t('ADD_CONTRACT.STREET_NUMBER')}*`}
              name="streetNumber"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.streetNumber}
              error={submitted ? formik.errors.streetNumber : undefined}
            />
          </div>

        </div>
        <div className='buttons-holder center'>
          <Button
            className='main-btn'
            variant='contained'
            size='large'
            type="button"
            onClick={() => {
              handleSubmitButtonState('primary')
              formik.handleSubmit()
            }}
          >
            {errorAlertMsg ? t('BUTTON.TRY_AGAIN') : t('BUTTON.SUBMIT')}
          </Button>
          {errorAlertMsg && (
            <Button
              className='secondary-btn'
              variant='contained'
              size='large'
              type="button"
              onClick={() => {
                handleSubmitButtonState('secondary')
                formik.handleSubmit()
              }}
            >
              {t('ADD_CONTRACT.SUBMIT_MANUAL_APPROVAL')}
            </Button>
          )}
        </div>
      </form>
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={() => setShowErrorAlert(false)} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Alert severity='error' sx={{ width: '100%' }} variant='filled'>
          {errorAlertMsg}
        </Alert>
      </Snackbar>
    </div>
  )
}
