import React from 'react'
import { useTranslation } from 'react-i18next'
import { homepageItems } from '../../config'
import { QuickLinkItem } from './QuickLinkItem'

export const QuickLinks = () => {
  const { t } = useTranslation()
  return (
    <div className='quick-links-holder'>
      <div className='services-board-list-head'>
        <h2>{t('HOME.QUICK_LINKS')}</h2>
      </div>
      <div className='services-board-list-holder'>
        {homepageItems?.map((item, index) => {
          const name = `HOME.${item.name}`
          return (
            <QuickLinkItem
              key={index}
              name={name}
              image={item.image}
              link={item.link}
            />
          )
        })}
      </div>
    </div>
  )
}
