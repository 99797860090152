import React from 'react'

export const CustomTextarea = ({
  label,
  value,
  onChange,
  name,
  placeholder,
  error
}: {
  label: string,
  value: string,
  onChange: any,
  name: string,
  placeholder: string,
  error: string | undefined
}) => {
  return (
    <div className='custom-input'>
      <label>{label}</label>
      <textarea
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && <p className='error'>{error}</p>}
    </div>
  )
}