import React from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

export const InfoAlert = (
  {
    closeInfoAlert
  } : {
    closeInfoAlert: any
  }
) => {
  const { t } = useTranslation()
  return (
    <div className='info-alert'>
      <div className='info-alert-header'>
        <div className='info-alert-head'>
          <ExclamationCircleIcon />
          <h3>{t('HOME.INFO')}</h3>
        </div>
        <button
          className='close-btn'
          onClick={closeInfoAlert}
        >
          <XMarkIcon />
        </button>
      </div>
      <div className='info-alert-body'>
        <p>{t('HOME.INFO_TEXT')}</p>
      </div>
    </div>
  )
}