import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@mui/material'
import { Snackbar } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { CustomInput, CustomSelect, CustomTextarea, CustomUplaodInput } from '../../../components/FormComponets'
import ImagePreview from '../../../components/ImagePreview'
import Modal from '../../../components/Modal'
import { serviceRequestApi } from '../../../api/serviceRequest'
import LoadingOverlay from '../../../components/LoadingOverlay'
import { ContractEntity, ServiceRequestCategoryEntity, ServiceRequestUrgencyEntity } from '../../../entities'
import { GlobalContext } from '../../../App'
import { ContextEntity } from '../../../customEntites/context.entity'
import { AttachmentEntity } from '../../../customEntites/Attachment.entity'
import { generateError } from '../../../services/error.service'

export const RepairAndInquiriesRequest = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { context }: ContextEntity = useContext(GlobalContext)

  const [attachments, setAttachments] = useState<AttachmentEntity[]>([])
  const [submitted, setSubmitted] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState(false)
  const [urgenciesLoading, setUrgenciesLoading] = useState(false)
  const [categoryOptions, setCategoryOptions] = useState<{label: string, value: string}[]>([])
  const [urgencyOptions, setUrgencyOptions] = useState<{label: string, value: string}[]>([])
  const [contractNumberOptions, setContractNumberOptions] = useState<{label: string, value: string}[]>([])
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMsg, setErrorAlertMsg] = useState('')


  const SignupSchema = Yup.object().shape({
    subject: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`),
    contractNumber: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`),
    category: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`),
    description: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`),
    urgency: Yup.string().required(`${t('FORM.REQUIRED_FIELD')}`)
  })
  const formik = useFormik({
    initialValues: {
      subject: '',
      contractNumber: '',
      category: '',
      description: '',
      urgency: ''
    },
    onSubmit: values => {
      setShowLoadingOverlay(true);
      (async () => {
        const categoryObject: ServiceRequestCategoryEntity = JSON.parse(formik.values.category)
        const urgencyObject: ServiceRequestCategoryEntity = JSON.parse(formik.values.urgency)
        const contract: ContractEntity|undefined = context?.contracts?.activeContracts?.find((contract) => contract?.contractNumber === formik.values.contractNumber)
        if (contract) {
          const attachmentsFiles = attachments.map((attachment) => attachment.file)
          await serviceRequestApi.createServiceRequest(
            {
              ...values,
              category: categoryObject.id,
              urgency: urgencyObject.name,
              contractNumber: formik.values.contractNumber,
              phone: contract?.phone,
              email: contract?.email,
              name: contract?.name,
              contact_id: contract?.contactId,
              contract_id: contract?.contractId
            },
            attachmentsFiles
          )
            .then(() => {
              setShowSuccessModal(true)
              setShowErrorAlert(false)
            })
            .catch((err) => {
              setShowErrorAlert(true)
              setErrorAlertMsg(generateError(err?.response?.data?.message))
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            })
        }
        setShowLoadingOverlay(false)
      })()
    },
    validationSchema: SignupSchema
  })

  useEffect(() => {
    (async () => {
      await setCategories()
      await setUrgencies()
    })()
  }, [t])

  useEffect(() => {
    if (context?.contracts?.activeContracts) {
      const contracts = context?.contracts?.activeContracts
      const contractNumberOptions = []
      for (const contract of contracts) {
        if (contract?.contractNumber) {
          contractNumberOptions.push({ value: contract?.contractNumber, label: `${contract?.contractNumber?.replace('-', '/')}, ${contract?.address}` })
        }
      }
      setContractNumberOptions(contractNumberOptions)
    }
  }, [context?.contracts?.activeContracts])

  useEffect(() => {
    if (formik.isSubmitting) {
      setSubmitted(true)
    }
  }, [formik])

  const setCategories = async () => {
    setCategoriesLoading(true)
    const lang = i18n.language.toUpperCase() || 'DE'
    await serviceRequestApi.getCategories(lang)
      .then((result) => {
        const categoryOptions = []
        for (const category of result) {
          if (category.name && category.id) {
            categoryOptions.push({
              value: JSON.stringify(category),
              label: category.name
            })
          }
        }
        setCategoryOptions(categoryOptions)
        setShowErrorAlert(false)
      })
      .catch((err) => {
        setShowErrorAlert(true)
        setErrorAlertMsg(generateError(err?.response?.data?.message))
      })
    setCategoriesLoading(false)
  }

  const setUrgencies = async () => {
    setUrgenciesLoading(true)
    await serviceRequestApi.getUrgencies()
      .then((result) => {
        const urgencyOptions = []
        for (const urgency of result) {
          if (urgency.name && urgency.id) {
            const localizedString = urgency.name.split(' ').join('').toUpperCase()
            urgencyOptions.push({
              value: JSON.stringify(urgency),
              label:t(`REPAIR_AND_INQUIRIES.URGENCY_OPTIONS.${localizedString}` as keyof typeof t)
            })
          }
        }
        setUrgencyOptions(urgencyOptions)
        setShowErrorAlert(false)
      })
      .catch((err) => {
        setShowErrorAlert(true)
        setErrorAlertMsg(generateError(err?.response?.data?.message))
      })
    setUrgenciesLoading(false)
  }

  const onSuccessModalCancel = () => {
    setShowSuccessModal(false)
    navigate('/repairs-and-inquiries', { state: { from: '/repairs-and-inquiries/new-request' }})
  }

  return (
    <div>
      {showLoadingOverlay && (
        <LoadingOverlay />
      )}
      {showSuccessModal && (
        <Modal
          head={`${t('REPAIR_AND_INQUIRIES.THANK_YOU')}`}
          text={`${t('REPAIR_AND_INQUIRIES.SUCCESSFULLY_REQUEST')}`}
          cancelText={`${t('REPAIR_AND_INQUIRIES.BACK_TO_REPAIRS_AND_INQUIRES')}`}
          onCancel={() => onSuccessModalCancel()}
          onConfirm={null}
          confirmText={null}
        />
      )}
      <form aria-autocomplete='none'  autoComplete='off' className='form-center' onSubmit={formik.handleSubmit}>
        <CustomSelect
          label={`${t('REPAIR_AND_INQUIRIES.CONTRACT_NUMBER')}*`}
          name="contractNumber"
          type="text"
          onChange={formik.setFieldValue}
          value={{
            value: formik.values.contractNumber,
            label: (() => {
              const contract = context?.contracts?.activeContracts?.find((contract) => contract?.contractNumber === formik.values.contractNumber)
              const contractNumberObject: any = contract ? contract : ''
              return typeof contractNumberObject === 'object' ? (
                `${contractNumberObject.contractNumber?.replace('-', '/')}, ${contractNumberObject.address}`
              ) : contractNumberObject
            })()
          }}
          placeholder={`${t('REPAIR_AND_INQUIRIES.SELECT_CONTRACT_NUMBER')}*`}
          options={contractNumberOptions}
          error={submitted ? formik.errors.contractNumber : undefined}
        />
        <CustomInput
          label={`${t('REPAIR_AND_INQUIRIES.SUBJECT')}*`}
          name="subject"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.subject}
          placeholder={`${t('REPAIR_AND_INQUIRIES.ENTER_SUBJECT')}*`}
          error={submitted ? formik.errors.subject : undefined}
        />
        <CustomSelect
          label={`${t('REPAIR_AND_INQUIRIES.CATEGORY')}*`}
          name="category"
          type="text"
          onChange={formik.setFieldValue}
          loading={categoriesLoading}
          value={{
            value: formik.values.category,
            label: ((): string => {
              const categoryObject: ServiceRequestCategoryEntity = formik.values.category ? JSON.parse(formik.values.category) : ''
              return typeof categoryObject === 'object' ? (
                categoryObject.name
              ) : categoryObject
            })()
          }}
          placeholder={`${t('REPAIR_AND_INQUIRIES.SELECT_CATEGORY')}*`}
          options={categoryOptions}
          error={submitted ? formik.errors.category : undefined}
        />
        <CustomSelect
          label={`${t('REPAIR_AND_INQUIRIES.URGENCY')}*`}
          name="urgency"
          type="text"
          onChange={formik.setFieldValue}
          loading={urgenciesLoading}
          value={{
            value: formik.values.urgency,
            label: (() => {
              const urgencyObject: ServiceRequestUrgencyEntity = formik.values.urgency ? JSON.parse(formik.values.urgency) : ''
              return typeof urgencyObject === 'object' ? (
                t(`REPAIR_AND_INQUIRIES.URGENCY_OPTIONS.${urgencyObject.name.split(' ').join('').toUpperCase()}`)
              ) : urgencyObject
            })()
          }}
          placeholder={`${t('REPAIR_AND_INQUIRIES.SELECT_URGENCY')}*`}
          options={urgencyOptions}
          error={submitted ? formik.errors.urgency : undefined}
        />
        <CustomTextarea
          label={`${t('REPAIR_AND_INQUIRIES.DESCRIPTION')}*`}
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
          placeholder={`${t('REPAIR_AND_INQUIRIES.ENTER_DESCRIPTION')}*`}
          error={submitted ? formik.errors.description : undefined}
        />
        <ImagePreview
          attachments={attachments}
          setAttachments={setAttachments}
          uploader={
            <CustomUplaodInput
              supportedFileTypes='(pdf / jpg / jpeg / png)'
              setAttachments={setAttachments}
              label={`${t('REPAIR_AND_INQUIRIES.UPLOAD_ATTACHMENT')}`}
            />
          }
          remove={true}
        />
        <div className='buttons-holder center'>
          <Button type='submit' className='main-btn' variant='contained' size='large'>{t('BUTTON.SUBMIT')}</Button>
          <Button onClick={() => navigate('/repairs-and-inquiries')} className='secondary-btn' variant='contained' size='large'>{t('BUTTON.CANCEL')}</Button>
        </div>
      </form>
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={() => setShowErrorAlert(false)} anchorOrigin={{horizontal:'right', vertical:'bottom'}}>
        <Alert severity='error' sx={{ width: '100%' }} variant='filled'>
          {errorAlertMsg}  
        </Alert>
      </Snackbar>
    </div>
  )
}
