import React from 'react'
import { useTranslation } from 'react-i18next'

const ContractsPageHeading = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='page-heading'>
        <h1>{t('NAVBAR.CONTRACTS')}</h1>
      </div>
      <div className='page-subheading'>
        <p>{t('CONTRACTS.SUBHEAD_1')}</p>
      </div>
    </div>
  )
}

export default ContractsPageHeading
