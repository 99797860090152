import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { Snackbar } from '@mui/material'
import { Alert } from '@mui/material'

import { serviceRequestApi } from '../../../api/serviceRequest'
import LoadingOverlay from '../../../components/LoadingOverlay'
import { GetServiceRequestEntity } from '../../../entities'
import { NoRequests, Multipanel } from './components'
import { GlobalContext } from '../../../App'
import { ContextEntity } from '../../../customEntites/context.entity'

import './index.scss'
import { generateError } from '../../../services/error.service'

export const RepairAndInquiries = () => {
  const [requests, setRequests] = useState<GetServiceRequestEntity[]>([])
  const [activeStatus, setActiveStatus] = useState(0)
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const { context }: ContextEntity = useContext(GlobalContext)
  const location = useLocation()
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMsg, setErrorAlertMsg] = useState('')
  const [activeItem, setActiveItem] = useState<GetServiceRequestEntity>({
    code: '',
    contract_number: '',
    create_date: '',
    description: '',
    main_issue: '',
    status: '',
    subject: '',
    urgency: '',
    zFeedbackTenantAppKTU: '',
    contacted_via: '',
    user_comment: []
  })

  useEffect(() => {
    (async () => {
      if (context?.contracts?.activeContracts?.length > 0) {
        setLoading(true)
        const requests: GetServiceRequestEntity[] = []
        const contractsList: string[] = []

        for (let i = 0; i < context?.contracts?.activeContracts?.length; i++) {
          const contractNumber = context.contracts.activeContracts[i]?.contractNumber
          contractsList.push(contractNumber)
        }

        const previousPage = location?.state?.from || ''
        const forceBtpRequest = previousPage === '/repairs-and-inquiries/new-request'
        location.state = null
        await serviceRequestApi.getServiceRequestsByContractsList(JSON.stringify(contractsList), activeStatus, forceBtpRequest)
          .then(res => {
            requests.push(...res)
            setShowErrorAlert(false)
          })
          .catch(err => {
            setShowErrorAlert(true)
            setErrorAlertMsg(generateError(err?.response?.data?.message))
          })

        setRequests(requests)
        const srNumber = searchParams.get('srNumber')

        if (srNumber) {
          const activeItem = requests.find((request) => request.code == srNumber)
          setActiveItem(activeItem || requests[0] || null)

        } else {
          setActiveItem(requests[0] || null)
        }
      }
      setLoading(false)
    })()
  }, [context?.contracts?.activeContracts, activeStatus])

  return (
    <div>
      {loading && <LoadingOverlay />}
      {!loading && (requests?.length > 0 || (requests?.length === 0 && activeStatus !== 0)) && (
        <>
          <Multipanel
            requests={requests}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            activeStatus={activeStatus}
            setActiveStatus={setActiveStatus}
            setRequests={setRequests}
          />
        </>
      )}
      {!loading && activeStatus === 0 && requests?.length === 0 && (
        <>
          <NoRequests />
        </>
      )}
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={() => setShowErrorAlert(false)} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Alert severity='error' sx={{ width: '100%' }} variant='filled'>
          {errorAlertMsg}
        </Alert>
      </Snackbar>
    </div>
  )
}
