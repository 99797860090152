import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import ContractsImage from '../../assets/icons/contracts-large'
import './index.scss'
import { useNavigate } from 'react-router-dom'

const NoContractOverlay = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className='no-contracts-overlay'>
      <h3>{t('ADD_CONTRACT.NO_CONTRACT_ADDED_YET')}</h3>
      <div className='contract-image'>
        {ContractsImage}
      </div>
      <div className='add-contract-btn-holder'>
        <Button
          className='main-btn'
          size='large'
          variant='contained'
          onClick={() => navigate('/contracts/add-contract')}
        >
          {t('ADD_CONTRACT.ADD_CONTRACT')}
        </Button>
      </div>
    </div>
  )
}

export default NoContractOverlay
