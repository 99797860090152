import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../../../assets/logo.png'
import { menuItems } from '../../config'
import MenuItem from '../MenuItem'
import './index.scss'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../services/language.service'
import { Collapse } from '@mui/material'

export const SideBar = ({
  open,
  setOpen,
  isMobile,
  setLogoutModal
}: {
  open: boolean,
  setOpen: any,
  isMobile: boolean,
  setLogoutModal: () => void
}) => {
  const location = useLocation()
  const [selectedLanguage, setSelectedLanguage] = useState<string|null>('')
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedLanguage(i18n.language)
  }, [])

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage)
      localStorage.setItem('SELECTED_LNG', selectedLanguage)
    }
  }, [selectedLanguage])

  return (
    <div className='sidebar-wrapper'>
      <div className='sidebar-header'>
        {isMobile && (
          <button className='toggler-icon' onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </button>
        )}
        <Link className='logo' to='/repairs-and-inquiries'>
          <img src={Logo} />
        </Link>
        <div className='translate-wrapper'>
          <button className={`${selectedLanguage === 'EN' ? 'active' : ''}`} onClick={() => setSelectedLanguage('EN')}>EN</button>
          <button className={`${selectedLanguage === 'DE' ? 'active' : ''}`} onClick={() => setSelectedLanguage('DE')}>DE</button>
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className='menu-wrapper'>
          {menuItems?.map((menuItem) => {
            const isActive = (menuItem.path === location.pathname || menuItem.subpath.some((path) => path === location.pathname) || menuItem.submenu.some((path) => path.path === location.pathname)) ? true : false
            const name = `NAVBAR.${menuItem.name}`
            return (
              <div key={menuItem.name}>
                <MenuItem
                  name={t(name)}
                  path={menuItem.path}
                  Icon={menuItem.svg}
                  active={isActive}
                  submenu={menuItem.submenu}
                  onClick={menuItem.name === 'LOG_OUT' ? () => {
                    setLogoutModal()
                    isMobile && setOpen(false)
                  } : null}
                />
              </div>
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}
