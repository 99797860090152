import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const NoRequests = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className='no-requests-holder'>
      <h4>{t('REPAIR_AND_INQUIRIES.CURRENTLY_NO_REQUESTS')}</h4>
      <Button
        className='main-btn'
        size='large'
        variant='contained'
        onClick={() => navigate('/repairs-and-inquiries/new-request')}
      >
        {t('REPAIR_AND_INQUIRIES.CREATE_REQUEST')}
      </Button>
    </div>
  )
}
