import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import React from 'react'

const LoadingOverlay = ({
  position,
  purpose,
  uploading
}: {
  position?: string|undefined,
  purpose?: string,
  uploading?: boolean
}) => {
  return (
    <div className={`loading-overlay ${position ? position : ''} ${purpose ? purpose : ''}`}>
      <div className={`loader ${uploading ? 'uploading' : ''}`}>
        {uploading && (
          <div className='uploading-animation-holder'>
            <div className='uploading-icon-holder'><CloudArrowUpIcon /></div>
            <div className='uploading-dots-holder'>
              <div className='uploading-dot'></div>
              <div className='uploading-dot'></div>
              <div className='uploading-dot'></div>
              <div className='uploading-dot'></div>
              <div className='uploading-dot'></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadingOverlay
