import React, { useEffect, useState } from 'react'

import { AuthenticatedTemplate, useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Modal from '../../components/Modal'
import useCurrentWidth from '../../customHooks/resizeWindowHook'
import { Footer, Header, PageHeading, SideBar } from './components'
import './index.scss'

const LayoutWrapper = () => {
  const isMobile = useCurrentWidth()
  const location = useLocation()
  let pageHeading = ''
  let headingHeader = ''
  let redirectLink = ''
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [open, setOpen] = useState(false)

  const { instance } = useMsal()

  switch (location.pathname) {
  case '/login':
  case '/register':
  case '/':
    pageHeading = 'home'
    break
  case '/account':
    pageHeading = 'account'
    break
  case '/repairs-and-inquiries':
    pageHeading = 'repairs_and_inquiries'
    break
  case '/repairs-and-inquiries/new-request':
    headingHeader = t('NAVBAR.NEW_REQUEST')
    redirectLink = '/repairs-and-inquiries'
    break
  case '/contracts':
    pageHeading = 'contracts'
    break
  case '/contracts/add-contract':
    headingHeader = t('NAVBAR.ADD_CONTRACT')
    redirectLink = '/contracts'
    break
  case '/account/edit-email':
    headingHeader = 'Edit Email'
    redirectLink = '/account'
    break
  case '/account/edit-password':
    headingHeader = 'Edit Password'
    redirectLink = '/account'
    break
  case '/account/delete-account':
    headingHeader = 'Delete Account'
    redirectLink = '/account'
    break
  default:
    headingHeader = ''
    redirectLink = ''
    pageHeading = ''
  }

  useEffect(() => {
    if (isMobile) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [isMobile])

  useEffect(() => {
    setShowLogoutModal(false)
    if (isMobile) {
      setOpen(false)
    }
  }, [location])

  const onLogoutAction = (action: string) => {
    if (action === 'logout') {
      instance.logout()
      setShowLogoutModal(false)
    } else {
      setShowLogoutModal(false)
      navigate('/')
    }
  }

  const isHomePage = location.pathname === '/' ? true : false

  return (
    <div className={`layout-wrapper ${isMobile ? 'mobile' : ''}`}>
      <AuthenticatedTemplate>
        <div className='navbar-wrapper'>
          <SideBar
            open={open}
            isMobile={isMobile}
            setOpen={setOpen}
            setLogoutModal={() => setShowLogoutModal(true)}
          />
        </div>
      </AuthenticatedTemplate>
      <main className='main-wrapper'>
        {showLogoutModal && (
          <Modal
            head={`${t('LOG_OUT.LOG_OUT')}`}
            text={`${t('LOG_OUT.ARE_YOU_SURE')}`}
            confirmText={`${t('BUTTON.YES')}`}
            cancelText={`${t('BUTTON.NO')}`}
            zIndex={1000}
            onConfirm={() => onLogoutAction('logout')}
            onCancel={() => onLogoutAction('cancel')}
          />
        )}
        {headingHeader && redirectLink && (
          <Header heading={headingHeader} redirectLink={redirectLink} />
        )}
        <div className='background-holder'></div>
        <div className='main-content-wrapper wrapper'>      
          {pageHeading && (
            <PageHeading page={pageHeading} />
          )}
          <Outlet />
          {isHomePage && (
            <p
              className='aroundtown_intro'
            >
              <Link
                style={{textTransform: 'uppercase'}}
                target='_blank'
                rel="noreferrer"
                to='https://www.aroundtown.de/'
              >
                {t('HOME.AROUNDTOWN_INTRO_LINK')}
              </Link>
              {t('HOME.AROUNDTOWN_INTRO')}
            </p>
          )}
        </div>
      </main>
      <AuthenticatedTemplate>
        <Footer />
      </AuthenticatedTemplate>
    </div>
  )
}

export default LayoutWrapper
