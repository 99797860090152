import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { de, en } from '../translations'

const savedLanguage = localStorage.getItem('SELECTED_LNG')

i18n.use(initReactI18next).init({
  resources: {
    EN: {
      translation: en
    },
    DE: {
      translation: de
    }
  },
  returnNull: false,
  fallbackLng: 'DE'
})

if (savedLanguage) {
  i18n.changeLanguage(savedLanguage)
} else {
  const envLng = process.env.REACT_APP_DEFAULT_LANGUAGE
  if (envLng) {
    i18n.changeLanguage(envLng)
  } else {
    i18n.changeLanguage('DE')
  }
}

export default i18n
