import React from 'react'
import { ContractEntity } from '../../../../entities'
import { ContractListItem } from './ContractListItem'

export const ContractList = ({contracts}: {contracts: ContractEntity[]}) => {
  return (
    <div className='contract-list'>
      {contracts?.map((contract, index) => {
        return (
          <ContractListItem key={index} contract={contract} />
        )
      })}
    </div>
  )
}