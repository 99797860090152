import React from 'react'

import { Button } from '@mui/material'

const Modal = ({
  head,
  text,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  zIndex
}: {
  head: string,
  text: string,
  confirmText: string|null,
  cancelText: string|null,
  onConfirm: any,
  onCancel: any,
  zIndex?: number
}) => {
  return (
    <div className='modal' style={{zIndex: zIndex || 2}}>
      <div className='wrapper'>
        <div className='page-heading'>
          <h1>{head}</h1>
        </div>
        <h4>{text}</h4>
        <div className='buttons-holder center margin-t-50'>
          {onConfirm && confirmText && (
            <Button
              type='button'
              className='main-btn'
              variant='contained'
              size='large'
              onClick={onConfirm}
            >
              {confirmText}
            </Button>
          )}
          {onCancel && cancelText && (
            <Button
              type='button'
              className='secondary-btn'
              variant='contained'
              size='large'
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
