import React, { useState } from 'react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import './index.scss'

export const CustomInput = ({
  label,
  value,
  onChange,
  name,
  type,
  placeholder,
  error,
  instruction,
  inputStyle,
  containerStyle
}: {
  label: string,
  value: string,
  onChange: any,
  name: string,
  type: string,
  placeholder?: string
  error: string | undefined,
  instruction?: string | undefined,
  inputStyle?: object,
  containerStyle?: object
}) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div className='custom-input' style={{...containerStyle}}>
      <label>{label}</label>
      <div className='input-holder'>
        <input
          aria-autocomplete='none'
          autoComplete='off'
          list="autocompleteOff" 
          style={{...inputStyle}}
          name={name}
          type={type === 'password' ? showPassword ? 'text' : 'password' : type}
          value={value}
          placeholder={placeholder || ''}
          onChange={onChange}
        />
        {type === 'password' && (
          <button className='eye-icon-btn' type='button' onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <EyeSlashIcon />
            ) : (
              <EyeIcon />
            )}
          </button>
        )}
      </div>
      {error && <p className='error'>{error}</p>}
      {instruction && <p className='instruction'>{instruction}</p>}
    </div>
  )
}