import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMsal } from '@azure/msal-react'
import { Alert, Button } from '@mui/material'
import { Snackbar } from '@mui/material'

import { userApi } from '../../../api/user'
import { b2cPolicies } from '../../../azureB2C/authConfig'
import { UserEntity } from '../../../entities'
import LoadingOverlay from '../../../components/LoadingOverlay'
import Modal from '../../../components/Modal'
import { generateError } from '../../../services/error.service'

export const Profile = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<UserEntity>()
  const [loading, setLoading] = useState(true)
  const [showUnregisteredModal, setShowUnregisteredModal] = useState(false)
  const { instance, accounts } = useMsal()
  const { i18n } = useTranslation()
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMsg, setErrorAlertMsg] = useState('')

  const language = i18n.language

  useEffect(() => {
    (async () => {
      setLoading(true)
      setTimeout(async () => {
        await userApi.getUserProfile()
          .then((res) => {
            setUser(res)
            setShowErrorAlert(false)
          })
          .catch((err) => {
            setShowErrorAlert(true)
            setErrorAlertMsg(generateError(err?.response?.data?.message))
          })
        setLoading(false)
        localStorage.removeItem('profile_edited')
      }, localStorage.getItem('profile_edited') === '1' ? 12000 : 0)
    })()
  }, [])

  const handleEditPersonalData = () => {
    instance.acquireTokenRedirect({
      ...b2cPolicies.authorities.editProfile,
      extraQueryParameters: {'ui_locales': `${language?.toLocaleLowerCase() || 'de'}`}
    })
  }

  const handleEditPassword = () => {
    instance.acquireTokenRedirect({
      ...b2cPolicies.authorities.changePassword,
      extraQueryParameters: {'ui_locales': `${language?.toLocaleLowerCase() || 'de'}`}
    })
  }

  const handleEditEmail = () => {
    instance.acquireTokenRedirect({
      ...b2cPolicies.authorities.changeEmail,
      extraQueryParameters: {'ui_locales': `${language?.toLocaleLowerCase() || 'de'}`}
    })
  }

  const handleDeleteUser = async () => {
    await userApi.deleteUser(accounts[0].localAccountId)
      .then(() => {
        instance.logoutRedirect()
      })
      .catch((err) => {
        setShowErrorAlert(true)
        setErrorAlertMsg(generateError(err?.response?.data?.message))
        setShowUnregisteredModal(false)
      })
  }

  return (
    <>
      {!loading ? (
        <>
          {user && (
            <>
              <div className='info-text-item'>
                <label>{t('ACCOUNT.BUSINESS_NAME')}</label>
                <p>{user?.businessName}</p>
              </div>
              <div className='info-text-item'>
                <label>{t('ACCOUNT.ADDRESS')}</label>
                <p>{user?.businessAddress}</p>
              </div>
              <div className='info-text-item'>
                <label>{t('ACCOUNT.CONTACT_PERSON')}</label>
                <p>{user?.contactPersonName}</p>
              </div>
              <div className='info-text-item'>
                <label>{t('ACCOUNT.CONTACT_PERSON_EMAIL')}</label>
                <p>{user?.contactPersonEmail}</p>
              </div>
              <div className='info-text-item'>
                <label>{t('ACCOUNT.EMAIL_ADDRESS')}</label>
                <p>{user?.email}</p>
              </div>
              <div className='info-text-item'>
                <label>{t('ACCOUNT.PHONE_NUMBER')}</label>
                <p>{user?.contactPersonPhone}</p>
              </div>
              <div className='buttons-holder column center gap-10 margin-t-50'>
                <Button className='main-btn' variant='contained' size='large' onClick={handleEditPersonalData}>{t('ACCOUNT.EDIT_PERSONAL_DATA')}</Button>
                <Button className='main-btn' variant='contained' size='large' onClick={handleEditPassword}>{t('ACCOUNT.EDIT_PASSWORD')}</Button>
                <Button className='main-btn' variant='contained' size='large' onClick={handleEditEmail}>{t('ACCOUNT.EDIT_EMAIL')}</Button>
                <Button className='main-btn' variant='contained' size='large' onClick={() => { setShowUnregisteredModal(true) }}>{t('ACCOUNT.DELETE_ACCOUNT')}</Button>
              </div>

              {showUnregisteredModal && (
                <Modal
                  head={`${t('DELETE_ACCOUNT.DELETE_ACCOUNT')}`}
                  text={`${t('DELETE_ACCOUNT.ARE_YOU_SURE')}`}
                  confirmText={`${t('BUTTON.YES')}`}
                  cancelText={`${t('BUTTON.NO')}`}
                  onConfirm={() => handleDeleteUser()}
                  onCancel={() => setShowUnregisteredModal(false)} />
              )}
            </>
          )}
        </>
      ) : (
        <LoadingOverlay />
      )}
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={() => setShowErrorAlert(false)} anchorOrigin={{horizontal:'right', vertical:'bottom'}}>
        <Alert severity='error' sx={{ width: '100%' }} variant='filled'>
          {errorAlertMsg}  
        </Alert>
      </Snackbar>
    </>
  )
}
