import React from 'react'
import { useTranslation } from 'react-i18next'

const AccountPageHeading = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div className='page-heading'>
        <h1>{t('NAVBAR.ACCOUNT')}</h1>
      </div>
    </div>
  )
}

export default AccountPageHeading
