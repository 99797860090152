import React, {
  useEffect,
  createContext,
  useState
} from 'react'
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import ActiveContractLayout from './layouts/ActiveContractLayout'
import AuthLayout from './layouts/AuthLayout'
import LayoutWrapper from './layouts/LayoutWrapper'
import NoAuthLayout from './layouts/NoAuthLayout'
import {
  Profile,
  RepairAndInquiries,
  RepairAndInquiriesRequest,
  AddContract,
  Home,
  Contracts
} from './pages/dashboard'
import { ContextEntity } from './customEntites/context.entity'
import LoadingOverlay from './components/LoadingOverlay'
import NotFound from './pages/NotFound'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Imprint from './pages/Imprint'
import { userApi } from './api/user'
import { useTranslation } from 'react-i18next'
import useFocusedInput from './customHooks/focusedInputHook'

export const GlobalContext = createContext<ContextEntity>(null!)

function App() {
  const { instance, accounts, inProgress } = useMsal()
  const [redirect, setRedirect] = useState(false)
  const [numberOfLoad, setNumberOfLoad] = useState(0)
  const [context, setContext] = useState({
    contracts: {
      activeContracts: [],
      contracts: []
    },
    alerts: [],
    focusedElement: '' as any
  })
  const focusedElement = useFocusedInput()
  const { i18n } = useTranslation()
  const pathname = window.location.pathname
  const search = window.location.search

  useEffect(() => {
    setContext((prev: any) => ({...prev, focusedElement}))
  }, [focusedElement])

  useEffect(() => {
    const script = document.createElement('script')
    const url = process.env.REACT_APP_COOKIE_URL || ''
    script.src = url
    document.head.appendChild(script)
  }, [])

  useEffect(() => {
    (async () => {
      await instance.handleRedirectPromise().then(async (res) => {
        const idTokenClaims: any = res?.idTokenClaims
        const isForgotPassword: any = idTokenClaims?.isForgotPassword
        if (res?.authority === `${process.env.REACT_APP_AZ_EDIT_PROFILE_AUTHORITY}/`?.toLowerCase()) {
          localStorage.setItem('profile_edited', '1')
        }
        if (res?.authority === `${process.env.REACT_APP_AZ_CHANGE_EMAIL_AUTHORITY}/`?.toLowerCase()) {
          localStorage.setItem('profile_edited', '1')
        }
        if (res?.authority === `${process.env.REACT_APP_AZ_CHANGE_PASSWORD_AUTHORITY}/`?.toLowerCase() && numberOfLoad === 0) {
          setNumberOfLoad(1)
          await userApi.changePasswordConfirmation()
        }
        if (res?.authority === `${process.env.REACT_APP_AZ_AUTHORITY}/`?.toLowerCase()) {
          localStorage.removeItem('home_info_alert')
          if (numberOfLoad === 0 && isForgotPassword) {
            setNumberOfLoad(1)
            await userApi.changePasswordConfirmation()
          }
        }
        if (res?.authority === `${process.env.REACT_APP_AZ_CHANGE_EMAIL_AUTHORITY}/`?.toLowerCase() && numberOfLoad === 0) {
          setNumberOfLoad(1)
          await userApi.changeEmailConfirmation()
        }
        setRedirect(true)
        const savedPath = localStorage.getItem('path')
        if (savedPath && (savedPath != '/' || savedPath.startsWith('/#state=')) && accounts.length > 0) {
          localStorage.removeItem('path')
          window.location.replace(savedPath)
        }
        if (!accounts[0] && inProgress != 'login' && redirect) {
          if (pathname != '/') {
            localStorage.setItem('path', pathname + search)
            window.location.replace('/')
          }
          const language = i18n.language
          instance.loginRedirect({
            scopes: [],
            extraQueryParameters: {'ui_locales': `${language?.toLocaleLowerCase() || 'de'}`}
          })
        }
      }).catch((err) => {
        if (err?.errorMessage?.includes('AADB2C90091')) {
          window.location.reload()
        } else {
          throw err
        }
      })
    })()
  }, [redirect])

  if (!accounts[0] || localStorage.getItem('path')) {
    return (
      <div>
        <LoadingOverlay purpose={'redirect-overlay'} />
      </div>
    )
  }

  return (
    <div className="App">
      <BrowserRouter>
        <GlobalContext.Provider value={{ context, setContext } as ContextEntity}>
          <Routes>
            <Route element={<LayoutWrapper />}>
              <Route element={<AuthLayout />}>
                <Route path='/contracts/add-contract' element={<AddContract />} />
                <Route path='/contracts' element={<Contracts />} />
                <Route element={<ActiveContractLayout />}>
                  <Route path='/' element={<Home />} />
                  <Route path='/repairs-and-inquiries' element={<RepairAndInquiries />} />
                  <Route path='/repairs-and-inquiries/new-request' element={<RepairAndInquiriesRequest />} />
                </Route>
                <Route path='/account' element={<Profile />} />
              </Route>
              <Route element={<NoAuthLayout />}>
              </Route>
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/datenschutzerklaerung' element={<PrivacyPolicy />} />
              <Route path='/imprint' element={<Imprint />} />
              <Route path='/impressum' element={<Imprint />} />
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        </GlobalContext.Provider>
      </BrowserRouter>
    </div>
  )
}

export default App
