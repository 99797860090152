import React from 'react'

const RepairsAndInquiriesImage = (
  <svg fill='#218CDA' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 200 200"  xmlSpace="preserve">

    <path className="st0" d="M175.4,0H24.6c-1.1,0-2,0.9-2,2v196c0,1.1,0.9,2,2,2h150.7c1.1,0,2-0.9,2-2V2C177.4,0.9,176.5,0,175.4,0
            L175.4,0z M173.4,196H26.6V4h146.7L173.4,196L173.4,196z M51.1,44.8c-0.8-0.8-0.8-2,0-2.8s2-0.8,2.8,0l7.1,7.1l12.2-12.2
            c0.8-0.8,2.1-0.8,2.8,0s0.8,2,0,2.8L62.5,53.3c-0.4,0.4-0.9,0.6-1.4,0.6s-1-0.2-1.4-0.6L51.1,44.8L51.1,44.8z M84.8,45.9
            c0-1.1,0.9-2,2-2h59.7c1.1,0,2,0.9,2,2s-0.9,2-2,2H86.8C85.7,47.9,84.8,47,84.8,45.9z M51.1,73.2c-0.8-0.8-0.8-2.1,0-2.8
            s2-0.8,2.8,0l7.1,7.1l12.2-12.2c0.8-0.8,2.1-0.8,2.8,0s0.8,2.1,0,2.8L62.5,81.7c-0.4,0.4-0.9,0.6-1.4,0.6s-1-0.2-1.4-0.6L51.1,73.2
            L51.1,73.2z M84.8,74.3c0-1.1,0.9-2,2-2h59.7c1.1,0,2,0.9,2,2s-0.9,2-2,2H86.8C85.7,76.3,84.8,75.4,84.8,74.3z M51.1,101.6
            c-0.8-0.8-0.8-2.1,0-2.8s2-0.8,2.8,0l7.1,7.1l12.2-12.2c0.8-0.8,2.1-0.8,2.8,0s0.8,2.1,0,2.8l-13.6,13.6c-0.4,0.4-0.9,0.6-1.4,0.6
            s-1-0.2-1.4-0.6L51.1,101.6L51.1,101.6z M84.8,102.7c0-1.1,0.9-2,2-2h59.7c1.1,0,2,0.9,2,2s-0.9,2-2,2H86.8
            C85.7,104.7,84.8,103.8,84.8,102.7z M84.8,131c0-1.1,0.9-2,2-2h59.7c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H86.8
            C85.7,133,84.8,132.1,84.8,131z M84.8,159.5c0-1.1,0.9-2,2-2h59.7c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H86.8
            C85.7,161.5,84.8,160.6,84.8,159.5z"
    />
  </svg>
)

export default RepairsAndInquiriesImage
