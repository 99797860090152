import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Collapse from '@mui/material/Collapse'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

const MenuItem = ({
  path,
  name,
  Icon,
  active,
  submenu,
  onClick
}: {
  path: string,
  name: string,
  Icon: any,
  active: boolean,
  submenu: {path: string, name: string}[],
  onClick: any
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const location = useLocation()

  const toggleExpand = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <div className='menu-item-holder'>
      <Link
        className={`menu-item ${active ? 'active' : ''} ${open ? 'open' : ''}`}
        to={path}
        onClick={(e) => {
          if (path === '#') {
            e.preventDefault()
          }
          if (onClick) {
            onClick()
          }
        }}
      >
        <Icon />
        {name}
        {submenu.length > 0 && (
          <span onClick={(e) => toggleExpand(e)} className='toggle-submenu'>
            {open ? (
              <ChevronUpIcon />
            ) : (
              <ChevronDownIcon />
            )}
          </span>
        )}
      </Link>
      {submenu.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className={`submenu-wrapper ${active ? 'active' : ''}`}>
            {submenu.map((item) => {
              const name = `NAVBAR.${item.name}`
              return (
                <li className={`submenu-item-holder ${item.path === location.pathname ? 'active' : ''}`} key={item.name}>
                  <Link className='submenu-item' to={item.path}>{t(name)}</Link>
                </li>
              )
            })}
          </div>
        </Collapse>
      )}
    </div>
  )
}

export default MenuItem
