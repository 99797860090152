import React from 'react'
import { useTranslation } from 'react-i18next'

const RepairAndInquiriesPageHeading = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='page-heading'>
        <h1>{t('NAVBAR.REPAIRS_AND_INQUIRIES')}</h1>
      </div>
      <div className='page-subheading'>
        <p>{t('REPAIR_AND_INQUIRIES.SUBHEAD_1')}</p>
        <p>{t('REPAIR_AND_INQUIRIES.SUBHEAD_2')}</p>
      </div>
    </div>
  )
}

export default RepairAndInquiriesPageHeading
