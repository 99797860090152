export const en = {
  'NAVBAR': {
    'REPAIRS_AND_INQUIRIES': 'Repairs & Inquiries',
    'ACCOUNT': 'Account',
    'LOG_OUT': 'Log out',
    'NEW_REQUEST': 'New Request',
    'WELCOME': 'MAIN MENU',
    'HOME': 'Home',
    'CONTRACTS': 'Contracts',
    'ADD_CONTRACT': 'Add Lease Contract'
  },
  'HOME': {
    'CLEAR_ALL': 'Clear all',
    'REPAIRS_AND_INQUIRIES': 'Repairs & Inquiries',
    'CONTRACTS': 'Contracts',
    'QUICK_LINKS': 'Quick links',
    'SUBHEAD_1': 'Welcome to the Aroundtown customer portal!',
    'SUBHEAD_2': 'Here you have the opportunity to send service requests to your contact person and track the status of your requests at any time.',
    'SUBHEAD_3': '- All in one place -',
    'AROUNDTOWN_INTRO_LINK': 'Aroundtown',
    'AROUNDTOWN_INTRO': ' is Germany\'s largest commercial real estate company and one of Europe\'s largest real estate companies. Experts from all areas of real estate management oversee the portfolio of office and commercial properties, retail and shopping centers, logistics and industrial sites. Your concerns are important to us! Since 2004, we have been a reliable partner for our tenants, who appreciate the comprehensive support provided by expert contact persons.',
    'INFO': 'Information for attention',
    'INFO_TEXT': 'Different users in the tenant portal, who use identical rental agreement numbers, can mutually view existing service requests for these rental agreement numbers.'
  },
  'FOOTER': {
    'CONTACT': 'Contact',
    'IMPRINT': 'Imprint',
    'PRIVACY_POLICY': 'Privacy Policy',
    'IMPORTANT_PHONE_NUMBERS': 'Important Phone Numbers',
    'SERVICE_CENTER': 'Service Center',
    'HOMEPAGE_AROUNDTOWN': 'Homepage AROUNDTOWN',
    'HOMEPAGE_AROUNDTOWN_SERVICE': 'Homepage AROUNDTOWN-Service',
    'HOMEPAGE_SNAPDESK': 'Homepage SNAPDESK'
  },
  'REPAIR_AND_INQUIRIES': {
    'CREATE_REQUEST': 'Create request',
    'CURRENTLY_NO_REQUESTS': 'Currently you have not  submitted any requests',
    'URGENT': 'Urgent',
    'SUBMITTED_ON': 'Submitted on',
    'CATEGORY': 'Category',
    'SELECT_CATEGORY': 'Select category',
    'DESCRIPTION': 'Description',
    'ENTER_DESCRIPTION': 'Enter description',
    'SUBJECT': 'Subject',
    'ENTER_SUBJECT': 'Enter subject',
    'FEEDBACK': 'Feedback',
    'URGENCY': 'Urgency',
    'SELECT_URGENCY': 'Select urgency',
    'CONTRACT_NUMBER': 'Contract number',
    'SELECT_CONTRACT_NUMBER': 'Select contract number',
    'BUSINESS_UNIT': 'Business unit',
    'SELECT_BUSINESS_UNIT': 'Select business unit',
    'AMEND': 'Amend',
    'SUCCESSFULLY_REQUEST': 'Your request has been successfully sent',
    'AMENDED_ON': 'Amended on',
    'AMENDMENT': 'Amendment',
    'ENTER_AMENDMENT': 'Enter amendment',
    'UPLOAD_ATTACHMENT': ' Upload attachment',
    'SUCCESSFULLY_AMENDED': 'You have successfully amended this request',
    'THANK_YOU': 'Thank you!',
    'BACK_TO_REPAIRS_AND_INQUIRES': 'Back to Repairs & Inquires',
    'NO_ATTACHMENTS': 'No attachments for preview',
    'URGENCY_OPTIONS': {
      'IMMEDIATE': 'Immediate',
      'URGENT': 'Urgent',
      'NORMAL': 'Normal',
      'LOW': 'Low'
    },
    'SUBHEAD_1': 'Record and track your service requests here.',
    'SUBHEAD_2': 'A previously added rental agreement in the Contracts section is required.'
  },
  'ACCOUNT': {
    'BUSINESS_NAME': 'Business name',
    'ADDRESS': 'Address',
    'CONTACT_PERSON': 'Contact person',
    'CONTACT_PERSON_EMAIL': 'Contact person email',
    'EMAIL_ADDRESS': 'Email address',
    'PHONE_NUMBER': 'Phone number',
    'CONTRACT_NUMBER': 'Contract number',
    'DELETE_ACCOUNT': 'Delete account',
    'EDIT_PERSONAL_DATA': 'Edit personal data',
    'EDIT_PASSWORD': 'Edit password',
    'EDIT_EMAIL': 'Edit email'
  },
  'ADD_CONTRACT': {
    'ADD_CONTRACT': 'Add Lease Contract',
    'ENTER_CONTRACT_DETAILS': 'Please enter your contract details.',
    'CONTRACT_NUMBER': 'Contract number',
    'ENTER_CONTRACT_NUMBER': 'Enter contract number',
    'CONTRACT_NUMBER_DESC': 'Please find this number in your contract and make sure you retype it correctly (the format has to be 0000/GW0000000).',
    'ADDRESS': 'Address',
    'ZIP': 'Zip',
    'CITY': 'City',
    'STREET': 'Street',
    'STREET_NUMBER': 'Number',
    'SELECT_ADDRESS': 'Select address',
    'SELECT_ZIP': 'Select zip',
    'SELECT_CITY': 'Select city',
    'SELECT_STREET': 'Select street',
    'ENTER_STREET_NUMBER': 'Enter street number',
    'NO_CONTRACT_RESPONSE': 'We were not able to identify your contract number. Please try again or just submit for manual approval. Our staff will check it out and activate your account.',
    'SUBMIT_MANUAL_APPROVAL': 'Submit For Manual Approval',
    'SUCCESSFULLY_ADDED': 'Your request has been successfully sent',
    'SUCCESSFULLY_MANUAL_ADDED': 'Your registration information has been sent for manual verification. You will be informed by email as soon as the verification has been completed.',
    'THANK_YOU': 'Thank you!',
    'BACK_TO_REPAIRS_AND_INQUIRES': 'Back to Repairs & Inquires',
    'BACK_TO_CONTRACTS': 'Back to Contracts',
    'NO_CONTRACT_ADDED_YET': 'You haven\'t added any contract yet. Click here to add the contract',
    'CONTRACT_EXIST': 'This contract has already been added.'
  },
  'ACTIVE_CONTRACT_OVERLAY': {
    'CONTRACTS_STATUS_PENDING': 'Pending - your contract information is currently being validated',
    'OVERLAY_TITLE': 'AVAILABLE ONCE YOU ADD A CONTRACT',
    'ADD_CONTRACT': 'Add a contract',
    'PLEASE_ADD_CONTRACT': 'Please add a contract'
  },
  'LOG_OUT': {
    'LOG_OUT': 'Log out',
    'ARE_YOU_SURE': 'Are you sure you want to log out?'
  },
  'DELETE_ACCOUNT': {
    'DELETE_ACCOUNT': 'Delete account',
    'ARE_YOU_SURE': 'Are you sure you want to delete account?'
  },
  'BUTTON': {
    'SUBMIT': 'Submit',
    'CANCEL': 'Back',
    'YES': 'Yes',
    'NO': 'No',
    'TRY_AGAIN': 'Try again',
    'PROCESSING': 'Processing'
  },
  'FORM': {
    'REQUIRED_FIELD': 'This field is required',
    'INVALID_CONTRACT_NUMBER': 'The contract number is invalid. Please note the format mentioned in the text.'
  },
  'ALERTS': {
    'STATUS': {
      'IN_PROGRESS': 'In progress',
      'RESOLVED': 'Resolved',
      'VERIFIED': 'Verified',
      'REJECTED': 'Rejected',
      'PENDING': 'Pending',
      'SUBMITTED': 'Submitted'
    },
    'CATEGORY': {
      'SERVICE_REQUEST': 'Repairs & Inquiries',
      'CONTRACT': 'Contract'
    }
  },
  'CONTRACTS': {
    'PENDING': 'Pending',
    'ACTIVE': 'Active',
    'INACTIVE': 'Denied',
    'ADD_CONTRACT': 'Add Lease contract',
    'SUBHEAD_1': 'Add the relevant rental contract here to create your service requests.'
  },
  'CATEGORIES': {
    'ALLGEMEINEANFRAGE': 'General Request',
    'MIETVERTRAG': 'Rent Contract',
    'KÜNDIGUNG': 'Termination',
    'DSLM': 'DSLM',
    'BUCHHALTUNG': 'Accounting',
    'BETRIEBSKOSTEN': 'Operational Costs',
    'MIETERHÖHUNG': 'Rent Increase',
    'MIETSCHULDEN': 'Rent Debts',
    'INAKTIVEMIETSCHULDEN': 'Inactive Rent Debts',
    'ÜBERNAHME': 'Handover',
    'CAPEX': 'CAPEX',
    'INSTANDHALTUNG': 'Maintenance',
    'VERSICHERUNG': 'Insurance',
  },
  'ERRORS': {
    'SOMETHING_WENT_WRONG': 'Something went wrong! Please try again!',
    'USER_NOT_FOUND': 'User has not been found',
    'GET_USER_PROFILE': 'Error while getting user profile',
    'DELETE_USER_PROFILE': 'Error while trying to delete user',
    'ENSURE_TOKEN': 'Error while ensuring a token',
    'CREATE_SERVICE_REQUEST': 'Error while creating a service request',
    'GET_SERVICE_REQUEST': 'Error while getting a service request',
    'GET_CONTRACTS': 'Error while getting contracts',
    'CREATE_AMENDMENT': 'Error while creating amendment',
    'GET_SERVICE_REQUEST_CATEGORIES': 'Error while getting service request categories',
    'SEND_ATTACHMENT': 'Error while sending attachment',
    'GET_ATTACHMENT': 'Error while getting attachments',
    'GET_ADDRESS': 'Error while getting addresses',
    'INVALID_CONTRACT': 'We were not able to identify your contract number. Please try again or just submit for manual approval. Our staff will check it out and activate your account.',
    'VERIFY_CONTRACT' :'Error while verifying a contract',
    'MANUAL_CONTRACT_VALIDATION': 'Error while manual contract validation',
    'TOKEN_GRAPH_API': 'Error while trying to get token for GraphAPI'
  }
}