import React from 'react'
import { Outlet } from 'react-router-dom'
import { AuthenticatedTemplate } from '@azure/msal-react'

const AuthLayout = () => {
  return (
    <AuthenticatedTemplate>
      <Outlet />
    </AuthenticatedTemplate>
  )
}

export default AuthLayout
