import React from 'react'
import './index.scss'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Imprint = () => {
  const location = useLocation()
  const { i18n } = useTranslation()
  if (location.pathname === '/imprint' && i18n.language === 'EN') {
    return (
      <div className='imprint-holder'>
        <div>
          <div className='page-heading'>
            <h1>Imprint</h1>
          </div>
          <div className='section'>
            <h2>Headquarter:</h2>
            <p>
              <h3>ATCP Management GmbH</h3>
              Ein Unternehmen der Aroundtown Gruppe
            </p>
            <p>
              Wittestraße 30, Haus F <br/>
              13509 Berlin
            </p>
            <p>
              Alleinvertretungsberechtigter Geschäftsführer: Oleg Zaslavsky<br/>
              Handelsregister: Amtsgericht Charlottenburg (Berlin) HRB 163540 B<br/>
              Umsatzsteueridentifikationsnummer: DE300136523
            </p>
          </div>
          <div className='section'>
            <h2>Contact:</h2>
            <p>
              E-Mail: <a href='mailto:atcp.info@aroundtown.de'>atcp.info@aroundtown.de</a><br/>
              Tel: +49 (0)30 374 381 9999
            </p>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='imprint-holder'>
        <div>
          <div className='page-heading'>
            <h1>Impressum</h1>
          </div>
          <div className='section'>
            <h2>Firmensitz:</h2>
            <p>
              <h3>ATCP Management GmbH</h3>
              Ein Unternehmen der Aroundtown Gruppe
            </p>
            <p>
              Wittestraße 30, Haus F <br/>
              13509 Berlin
            </p>
            <p>
              Alleinvertretungsberechtigter Geschäftsführer: Oleg Zaslavsky<br/>
              Handelsregister: Amtsgericht Charlottenburg (Berlin) HRB 163540 B<br/>
              Umsatzsteueridentifikationsnummer: DE300136523
            </p>
          </div>
          <div className='section'>
            <h2>Erreichbarkeit:</h2>
            <p>
              E-Mail: <a href='mailto:atcp.info@aroundtown.de'>atcp.info@aroundtown.de</a><br/>
              Tel: +49 (0)30 374 381 9999
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Imprint
