import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from '@mui/material'
import { Snackbar } from '@mui/material'
import { contractApi } from '../../../api/contract'
import { ContractList } from './components'
import { ContractEntity } from '../../../entities'
import LoadingOverlay from '../../../components/LoadingOverlay'
import NoContractOverlay from '../../../components/NoContractOvrlay/NoContractOverlay'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import { ContextEntity } from '../../../customEntites/context.entity'
import { GlobalContext } from '../../../App'
import { generateError } from '../../../services/error.service'

export const Contracts = () => {
  const { t } = useTranslation()
  const [contracts, setContracts] = useState<ContractEntity[]>([])
  const [loading, setLoading] = useState(true)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [errorAlertMsg, setErrorAlertMsg] = useState('')
  const navigate = useNavigate()
  const { setContext }: ContextEntity = useContext(GlobalContext)


  useEffect(() => {
    (async () => {
      setLoading(true)
      await contractApi.getContractsByAppId()
        .then(res => {
          const activeContracts = res.filter((contract) => contract.approvalState == 'approved')
          setContext((prev: any) => ({ ...prev, contracts: { activeContracts, contracts: res } }))
          setContracts(res)
          setShowErrorAlert(false)
        })
        .catch(err => {
          setShowErrorAlert(true)
          setErrorAlertMsg(generateError(err?.response?.data?.message))
        })
      setLoading(false)
    })()
  }, [])

  return (
    <div>
      {loading ? (
        <LoadingOverlay />
      ) : (
        contracts?.length === 0 ? (
          <>
            <NoContractOverlay />
          </>
        ) : (
          <div className='contract-list-holder'>
            <div className='add-contract-btn-holder'>
              <Button
                className='main-btn'
                size='large'
                variant='contained'
                onClick={() => navigate('/contracts/add-contract')}
              >
                {t('CONTRACTS.ADD_CONTRACT')}
              </Button>
            </div>
            <ContractList contracts={contracts} />
          </div>
        )
        
      )}
      <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={() => setShowErrorAlert(false)} anchorOrigin={{horizontal:'right', vertical:'bottom'}}>
        <Alert severity='error' sx={{ width: '100%' }} variant='filled'>
          {errorAlertMsg}  
        </Alert>
      </Snackbar>
    </div>
  )
}