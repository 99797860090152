import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './index.scss'

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer>
      <div className='footer-content wrapper'>
        <div className='group'>
          <div className='item'>
            <div>
              <Link to='/imprint'>{t('FOOTER.IMPRINT')}</Link>
              <Link to='/privacy-policy'>{t('FOOTER.PRIVACY_POLICY')}</Link>
            </div>
          </div>
          <div>
            <div className='item'>
              <h3>{t('FOOTER.IMPORTANT_PHONE_NUMBERS')}</h3>
              <div>
                <div className='info-item-wrap'>
                  <span>{t('FOOTER.SERVICE_CENTER')}: </span>
                  <Link to='tel:+49030221835400'>+49 (0)30 221 835 400</Link>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='item'>
              <h3>{t('FOOTER.HOMEPAGE_AROUNDTOWN')}</h3>
              <div>
                <div className='info-item-wrap'>
                  <Link
                    rel="noreferrer"
                    target='_blank'
                    to='https://www.aroundtown.de/'
                  >
                    Home (aroundtown.de)
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='item'>
              <h3>{t('FOOTER.HOMEPAGE_AROUNDTOWN_SERVICE')}</h3>
              <div>
                <div className='info-item-wrap'>
                  <Link
                    rel="noreferrer"
                    target='_blank'
                    to='https://aroundtown-service.de/de/'
                  >
                    aroundtown-service.de
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='item'>
              <h3>{t('FOOTER.HOMEPAGE_SNAPDESK')}</h3>
              <div>
                <div className='info-item-wrap'>
                  <Link
                    rel="noreferrer"
                    target='_blank'
                    to='https://snapdesks.de/'
                  >
                    Home (snapdesks.de)
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
