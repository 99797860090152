import React from 'react'
import './index.scss'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
  const location = useLocation()
  const { i18n } = useTranslation()
  if (location.pathname === '/privacy-policy' && i18n.language === 'EN') {
    return (
      <div className='privacy-policy-holder'>
        <div>
          <h1>Privacy policy for the ATCP web app</h1>
          <h2>This privacy policy informs you about the processing of your personal data by ATCP Management GmbH in connection with the ATCP web app.</h2>
          <div>
            <h2>1. General Notes and Mandatory Information</h2>
            <p>ATCP Management GmbH takes the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations, including the EU General Data Protection Regulation (GDPR), and this privacy policy.</p>
            <p>When you use our ATCP web app, various personal data is collected. Personal data is data that relates to an identified or identifiable natural person. This includes your name, your address and your IP address. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done.</p>
            <h2>Note on the controller</h2>
            <p>The controller for data processing in connection with the ATCP web app is:</p>
            <p>ATCP Management GmbH, Wittestraße 30, Haus F, 13509 Berlin</p>
            <p>Phone: +49 (0)30 374 381 – 9999</p>
            <p>Email:<a href='mailto:atcp.info@aroundtown.de'>atcp.info@aroundtown.de</a></p>
            <p>The data protection officer can be reached at the e-mail address: atcp.dsb@aroundtown.de</p>
            <p>The controller is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data.</p>
          </div>
        </div>
        <div>
          <h2>2. Accessing the ATCP web app</h2>
          <p>You can access the ATCP web app via <a href='https://serviceportal.aroundtown.de'>https://serviceportal.aroundtown.de</a> on your computer or mobile device.</p>
          <p>If you visit this website, we process your IP address, information on the device and browser you are using (operating system, browser type and version, host name of the device), the referrer URL and time and date of your request. We process this data to display you our website correctly, enable its core functions and to ensure the stability and security of our site.</p>
          <p>The legal basis for this data processing is Art. 6 (1) 1 lit. b GDPR, insofar as it serves to provide our website to you, and Art. 6 (1) 1 lit. f GDPR, as we have a legitimate interest to ensure the security and user-friendliness of the website as well as smooth access to its core functions.</p>
        </div>
        <div>
          <h2>3. Use of the ATCP web app as a tenant</h2>
          <h2>3.1 Tenant Portal</h2>
          <p>You can register yourself for the tenant portal, i.e. our ATCP web app on the website mentioned above. For this purpose, the following personal data will be collected: Your business name, business address, first and last name, your e-mail address, alternate e-mail address, your business telephone or mobile phone number and your preferred language. We process your personal data as far as this is necessary to provide the service you requested. For manual verification, your data will be forwarded to the responsible employee. An automated decision-making process - including profiling - does not take place.</p>
          <p>The legal basis is Article 6 (1) 1 lit. b GDPR. Insofar as the data processing does not serve the performance of the rental agreement, it is based on Article 6 (1) 1 lit. f GDPR. We have a legitimate interest in offering our tenants a comprehensive range of services so that they can contact us or find out about current offers. We store the data as long as you are registered in the tenant portal. If you delete your account, your data will be deleted within 24 hours after the account has been deactivated.</p>
          <h2>3.2 Contact Form</h2>
          <p>Within the ATCP web app, you have the possibility to send us your requests and damage reports via the contact form. If you use the contact form, we will process the contact data you provided during registration to the tenant portal as well as the content of the request for the purpose of answering your request. The legal basis is Article 6 (1) 1 lit. b GDPR.</p>
          <p>We store the data we process in the context of the request through the contact form for a period of 3 months after completion of the request or for 3 years if a rental agreement exists.</p>
          <h2>3.3 Feedback</h2>
          <p>In order to constantly improve our service, we appreciate your feedback. If you give feedback about our ATCP web app or our service in general, we will process your contact data as well as the content of the feedback to evaluate and adjust our offer. You can decide for yourself whether we may publish your feedback or not.</p>
          <p>The legal basis is Article 6 (1) 1 lit. f GDPR. We have a legitimate interest in receiving evaluations of our services in order to improve them. We store the data that we process in the context of feedback for a period of one week.</p>
          <h2>4. Disclosure of your personal data to external recipients</h2>
          <p>For some of the aforementioned purposes, we may disclose your personal data to other parties, such as IT security and other service providers. These categories of recipients are data processors acting on our behalf. The legal basis for respective data transfers is Art. 28 GDPR in connection with the respective data processing agreement concluded with the recipient. Through these agreements, we have contractually bound these recipients to process your personal data only on our behalf and in accordance with our instructions. As far as this is necessary for internal administrative and operational purposes, we may also transfer your personal data to other entities in the Aroundtown group of companies based on our legitimate interest in this regard (Art. 6 (1) (1) lit. f GDPR.</p>
          <h2>5. Third country data transfers</h2>
          <p>When sharing your personal data with external recipients, some of your personal data may be transferred to other countries, including third countries outside the EU / EEA where such laws may not provide the same level of protection for your personal data as the GDPR does. Please note that data processed in a foreign country may be subject to foreign laws and accessible to foreign governments, courts, law enforcement, and regulatory agencies. However, we will endeavor to take the required measures to maintain an adequate level of data protection when sharing your personal data with recipients established in such countries. In the case of a transfer to a country outside of the EEA, this transfer is either safeguarded by a so-called adequacy decision from the European Commission declaring that such country provides for an adequate level of data protection, or, if such adequacy decision does not exist, the conclusion of EU Standard contractual clauses <a href='https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en'>(https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en)</a> and additional measures, if necessary. In limited cases, we may rely on another exception under Art. 49 GDPR for the data transfer (such as your consent).</p>
          <h2>6. Data Protection Rights</h2>
          <p>Depending on the circumstances of the specific case, you have the following data protection rights:</p>
          <p>You have the right to request information about and access to your personal data and/or a copy of such data. This includes information on the purpose of use, the category of data used, the recipients and persons authorized to access such data and, if possible, the planned duration of data storage or, if this is not possible, the criteria for determining this duration.</p>
          <p>Many data processing operations are only possible with your express consent. You can refuse consent or revoke any consent you have already given at any time. For this purpose, an informal notification to us by e-mail is sufficient. The legality of the data processing carried out up to the time of revocation remains unaffected by the revocation.</p>
          <p>If you are of the opinion that your rights have been violated as a result of processing your personal data not in compliance with data protection regulations, you have the right to complain to the responsible supervisory authority.</p>
          <p>You have the right to have data that you have provided us with delivered to you or to a third party in a commonly used, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done to the extent that it is technically feasible. You have the right not to be subject to a decision based solely on automated processing that has legal effect against you or that significantly affects you in a similar way.</p>
          <p>You have the right to have your personal data corrected, blocked or deleted, insofar as its use is not permitted under data protection law. In particular, this is the case if (i) the data is incomplete or incorrect, (ii) it is no longer necessary for the purposes for which it was collected, (iii) the consent on which the processing was based has been revoked, or (iv) you have successfully exercised your right to object to data processing. In cases where the data is processed by third parties, we will forward your requests for correction, deletion or restriction of processing to these third parties, unless this proves impossible or involves a disproportionate effort.</p>
          <h2>You have the right to object to the processing of your data if we process your data for the purposes of direct marketing or if we process your data to pursue our legitimate interests and if there are reasons arising from your particular situation.</h2>
          <h2>7. Duration of the Storage of your Personal Data</h2>
          <p>Your personal data will be deleted in accordance with the periods of time stated above, if the purpose for which it was stored ceases to apply and no further storage is required by law. Statistical evaluations are only carried out in anonymized form.</p>
          <h2>8. Miscellaneous</h2>
          <p>The privacy policy set out above is valid from 01.12.2023 onwards. It can be viewed and saved/printed at any time via the ATCP web app. We reserve the right to change this privacy policy from time to time in compliance with data protection regulations.</p>
          <p>If you have any questions, suggestions or comments on the subject of data protection, please feel free to contact our data protection officer at atcp.dsb@aroundtown.de</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className='privacy-policy-holder'>
        <div>
          <h1>Datenschutzerklärung für die ATCP Web-App</h1>
          <p>Diese Datenschutzerklärung informiert Sie über die Verarbeitung Ihrer personenbezogenen Daten durch die ATCP Management GmbH im Zusammenhang mit der ATCP Web-App.</p>
          <div>
            <h2>1. Allgemeine Hinweise und obligatorische Informationen</h2>
            <p>Die ATCP Management GmbH nimmt den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und gemäß den gesetzlichen Datenschutzvorschriften, einschließlich der EU- Datenschutzgrundverordnung (DSGVO), sowie dieser Datenschutzerklärung.</p>
            <p>Wenn Sie unsere ATCP Web-App nutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Dazu gehören Ihr Name, Ihre Adresse und Ihre IP-Adresse. In dieser Datenschutzerklärung wird erläutert, welche Daten wir erheben und wofür wir sie verwenden. Es wird auch erklärt, wie und zu welchem Zweck dies geschieht.</p>
            <h2>Verantwortlicher</h2>
            <p>Der Verantwortliche für die Datenverarbeitung im Zusammenhang mit der ATCP Web-App ist:</p>
            <p>ATCP Management GmbH, Wittestraße 30, Haus F, 13509 Berlin</p>
            <p>Phone: +49 (0)30 374 381 – 9999</p>
            <p>Email:<a href='mailto:atcp.info@aroundtown.de'>atcp.info@aroundtown.de</a></p>
            <p>Den Datenschutzbeauftragten erreichen Sie unter der E-Mail-Adresse: atcp.dsb@aroundtown.de</p>
            <p>Der Verantwortliche ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</p>
          </div>
        </div>
        <div>
          <h2>2. Zugriff auf die ATCP Web-App</h2>
          <p>Sie können die ATCP Web-App übe <a href='https://serviceportal.aroundtown.de'>https://serviceportal.aroundtown.de</a> auf Ihrem Computer oder Mobilgerät aufrufen.</p>
          <p>Wenn Sie diese Website besuchen, verarbeiten wir Ihre IP-Adresse, Informationen über das von Ihnen verwendete Gerät und den Browser (Betriebssystem, Browsertyp und -version, Hostname des Geräts), die Referrer- URL sowie Uhrzeit und Datum Ihrer Anfrage. Wir verarbeiten diese Daten, um Ihnen unsere Website korrekt anzuzeigen, deren Kernfunktionalität zu gewährleisten und die Stabilität und Sicherheit unserer Website sicherzustellen. Die Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 UAbs. 1 lit. b DSGVO, soweit die Verarbeitung der Bereitstellung unserer Website für Sie dient, und Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO, da wir ein berechtigtes Interesse daran haben, die Sicherheit und Benutzerfreundlichkeit der Website sowie den reibungslosen Zugang zu deren Kernfunktionen zu gewährleisten.</p>
        </div>
        <div>
          <h2>3. Nutzung der ATCP Web-App als Mieter</h2>
          <h2>3.1 Mieterportal</h2>
          <p>Auf der oben genannten Website können Sie sich für das Mieterportal, d.h. unsere ATCP Web-App, registrieren. Zu diesem Zweck werden die folgenden personenbezogenen Daten erhoben: Ihr Firmenname, Ihre Geschäftsadresse, Ihr Vor- und Nachname, Ihre E-Mail-Adresse, Ihre alternative E-Mail-Adresse, Ihre geschäftliche Telefon- oder Mobilfunknummer und Ihre bevorzugte Sprache. Wir verarbeiten Ihre personenbezogenen Daten, soweit dies erforderlich ist, um die von Ihnen angeforderte Dienstleistung zu erbringen. Zur manuellen Überprüfung werden Ihre Daten an den jeweils zuständigen Mitarbeiter weitergeleitet. Eine automatisierte Entscheidungsfindung - einschließlich Profiling - findet nicht statt.</p>
          <p>Die Rechtsgrundlage ist Artikel 6 Abs. 1 UAbs. 1 lit. b DSGVO. Soweit die Datenverarbeitung nicht der Erfüllung des Mietvertrages dient, beruht sie auf Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse daran, unseren Mietern ein umfassendes Serviceangebot zu bieten, damit sie mit uns Kontakt aufnehmen oder sich über aktuelle Angebote informieren können. Wir speichern die Daten so lange, wie Sie im Mieterportal registriert sind. Wenn Sie Ihr Konto löschen, werden Ihre Daten innerhalb von 24 Stunden nach der Deaktivierung des Kontos gelöscht.</p>
          <h2>3.2 Kontakt-Formular</h2>
          <p>Innerhalb der ATCP Web-App haben Sie die Möglichkeit, uns Ihre Anfragen und Schadensmeldungen über das Kontaktformular mitzuteilen. Wenn Sie das Kontaktformular nutzen, verarbeiten wir die von Ihnen bei der Registrierung zum Mieterportal angegebenen Kontaktdaten sowie den Inhalt der Anfrage, um Ihre Anfrage zu beantworten. Die Rechtsgrundlage dafür ist Artikel 6 Abs. 1 UAbs. 1 lit. b DSGVO. Wir speichern die Daten, die wir im Rahmen der Anfrage über das Kontaktformular verarbeiten, für einen Zeitraum von 3 Monaten nach Erledigung der Anfrage bzw. für 3 Jahre, wenn ein Mietvertrag besteht.</p>
          <h2>3.3 Feedback</h2>
          <p>Um unseren Service ständig verbessern zu können, freuen wir uns über Ihr Feedback. Wenn Sie uns Feedback zu unserer ATCP Web-App oder unserem Service im Allgemeinen geben, verarbeiten wir Ihre Kontaktdaten sowie den Inhalt Ihres Feedbacks, um unser Angebot zu evaluieren und anzupassen. Sie können selbst entscheiden, ob wir Ihr Feedback veröffentlichen dürfen oder nicht.</p>
          <p>Rechtsgrundlage ist Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse daran, Bewertungen unserer Dienstleistungen zu erhalten, um sie zu verbessern. Wir speichern die Daten, die wir im Rahmen Ihres Feedbacks verarbeiten, für einen Zeitraum von einer Woche.</p>
          <h2>4. Weitergabe Ihrer personenbezogenen Daten an externe Empfänger</h2>
          <p>Für einige der oben genannten Zwecke können wir Ihre personenbezogenen Daten an Dritte weitergeben, z. B. an IT-Sicherheits- und andere Dienstleistungsanbieter. Diese Kategorien von Empfängern sind Auftragsverarbeiter, die in unserem Auftrag handeln. Die Rechtsgrundlage für die jeweiligen Datenübermittlungen stellt Art. 28 DSGVO dar in Verbindung mit dem jeweiligen Auftragsverarbeitungsvertrag, der mit dem Empfänger abgeschlossen wurde. Durch diese Vereinbarungen haben wir diese Empfänger vertraglich verpflichtet, Ihre personenbezogenen Daten nur in unserem Auftrag und gemäß unseren Weisungen zu verarbeiten. Soweit dies für interne Verwaltungs- und Betriebszwecke erforderlich ist, werden wir Ihre personenbezogenen Daten gegebenenfalls auch an andere Unternehmen der Aroundtown-Unternehmensgruppe auf der Grundlage unseres berechtigten Interesses in dieser Hinsicht übermitteln (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO).</p>
          <h2>5. Datenübermittlung an Drittländer</h2>
          <p>Bei der Weitergabe Ihrer personenbezogenen Daten an externe Empfänger werden einige Ihrer personenbezogenen Daten gegebenenfalls in andere Länder übertragen werden, auch in Drittländer außerhalb der EU/des EWR, in denen die dortigen Gesetze möglicherweise nicht das gleiche Schutzniveau für Ihre personenbezogenen Daten bieten wie die DSGVO. Bitte beachten Sie, dass Daten, die in einem anderen Land verarbeitet werden, ausländischen Gesetzen unterliegen und für ausländische Regierungen, Gerichte, Strafverfolgungs- und Aufsichtsbehörden zugänglich sein können. Wir werden uns jedoch bemühen, die erforderlichen Maßnahmen zu ergreifen, um ein angemessenes Datenschutzniveau aufrechtzuerhalten, wenn wir Ihre personenbezogenen Daten an Empfänger mit Sitz in solchen Ländern weitergeben. Im Falle einer Übermittlung in ein Land außerhalb des EWR wird diese Übermittlung entweder durch einen sogenannten Angemessenheitsbeschluss der Europäischen Kommission abgesichert, in dem erklärt wird, dass das jeweilige Land ein angemessenes Datenschutzniveau bietet, oder, falls ein solcher Angemessenheitsbeschluss für das jeweilige Land nicht existiert, durch den Abschluss von EU-Standardvertragsklauseln <a href='https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en'>(https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en)</a> und gegebenenfalls zusätzlichen Maßnahmen. In begrenzten Fällen können wir uns auf eine andere Ausnahme nach Art. 49 DSGVO für die Datenübermittlung stützen (z. B. auf Ihre Einwilligung).</p>
          <h2>6. Datenschutzrechte</h2>
          <p>Je nach den Umständen des Einzelfalls haben Sie die folgenden Datenschutzrechte:</p>
          <p>Sie haben das Recht, Auskunft über und Zugang zu Ihren personenbezogenen Daten und/oder eine Kopie dieser Daten zu verlangen. Dazu gehören Informationen über den Verwendungszweck, die Kategorie der verwendeten Daten, die Empfänger und die zum Zugriff auf diese Daten berechtigten Personen sowie, wenn möglich, die geplante Dauer der Datenspeicherung oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer.</p>
          <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Zustimmung möglich. Sie können Ihre Einwilligung verweigern oder eine bereits erteilte Einwilligung jederzeit widerrufen. Hierfür genügt eine formlose Mitteilung an uns per E-Mail. Die Rechtmäßigkeit der bis zum Zeitpunkt des Widerrufs erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Wenn Sie der Ansicht sind, dass Ihre Rechte durch eine nicht datenschutzkonforme Verarbeitung Ihrer personenbezogenen Daten verletzt wurden, haben Sie das Recht, sich bei der zuständigen Aufsichtsbehörde zu beschweren.</p>
          <p>Sie haben das Recht, dass die Daten, die Sie uns zur Verfügung gestellt haben, Ihnen oder einem Dritten in einem gängigen, maschinenlesbaren Format übermittelt werden. Wenn Sie die direkte Übermittlung der Daten an einen anderen für die Verarbeitung Verantwortlichen verlangen, erfolgt dies nur insoweit, wie es technisch machbar ist.</p>
          <p>Sie haben das Recht, keiner ausschließlich auf einer automatisierten Verarbeitung beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.</p>
          <p>Sie haben das Recht auf Berichtigung, Sperrung oder Löschung Ihrer personenbezogenen Daten, soweit deren Verwendung datenschutzrechtlich unzulässig ist. Dies ist insbesondere dann der Fall, wenn (i) die Daten unvollständig oder unrichtig sind, (ii) sie für die Zwecke, für die sie erhoben wurden, nicht mehr erforderlich sind, (iii) die Einwilligung, auf der die Verarbeitung beruhte, widerrufen wurde oder (iv) Sie Ihr Recht auf Widerspruch gegen die Datenverarbeitung erfolgreich ausgeübt haben. In Fällen, in denen Ihre personenbezogenen Daten von Dritten verarbeitet werden, werden wir Ihre Anträge auf Berichtigung, Löschung oder Einschränkung der Verarbeitung an diese Dritten weiterleiten, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.</p>
          <h2>Sie haben das Recht, der Verarbeitung Ihrer Daten zu widersprechen, wenn wir Ihre Daten für Zwecke des Direktmarketings verarbeiten oder wenn wir Ihre Daten zur Verfolgung unserer berechtigten Interessen verarbeiten und Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben.</h2>
          <h2>7. Dauer der Speicherung Ihrer personenbezogenen Daten</h2>
          <p>Ihre personenbezogenen Daten werden entsprechend den oben genannten Fristen gelöscht, wenn der Zweck der Speicherung entfällt und eine weitere Speicherung gesetzlich nicht vorgeschrieben ist. Statistische Auswertungen finden nur in anonymisierter Form statt.</p>
          <h2>8. Sonstiges</h2>
          <p>Die oben genannte Datenschutzerklärung ist ab dem 01.01.2023 gültig. Sie kann jederzeit über die ATCP Web-App eingesehen und gespeichert/ausgedruckt werden. Wir behalten uns das Recht vor, diese Datenschutzerklärung von Zeit zu Zeit gemäß den datenschutzrechtlichen Bestimmungen zu ändern.</p>
          <p>Wenn Sie Fragen, Anregungen oder Kommentare zum Thema Datenschutz haben, können Sie sich gerne an unseren Datenschutzbeauftragten wendendurch eine E-Mail an atcp.dsb@aroundtown.de</p>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicy
