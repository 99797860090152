import React  from 'react'
import { useDropzone } from 'react-dropzone'
import './index.scss'
import { AttachmentEntity } from '../../../customEntites/Attachment.entity'

export const CustomUplaodInput = ({
  label,
  supportedFileTypes,
  setAttachments
}: {
  label: string,
  supportedFileTypes?: string,
  setAttachments: any
}) => {

  const acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const filteredFiles: AttachmentEntity[] = []
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i]
        if (acceptedFileTypes.includes(file.type)) {
          filteredFiles.push({
            file,
            customProperties: {}
          })
        }
      }
      setAttachments((prev: any) => [...prev, ...filteredFiles])
    },
  })

  const handleMultipleAttachments = (event: any) => {
    const selectedFiles: any[] = []
    const targetFiles = event.target.files
    const targetFilesObject = [...targetFiles]
    targetFilesObject.forEach((file) => {
      selectedFiles.push(file)
    })
    setAttachments((prev: any) => [...prev, ...selectedFiles])
    event.target.value = null
  }

  return (
    <div
      className='custom-uplaod-input'
      {...getRootProps()}
    >
      <div
        className='upload-file-button'
      >
        +
      </div>
      <input
        {...getInputProps()}
        onChange={handleMultipleAttachments}
        type='file'
        accept={acceptedFileTypes.toString()}
        multiple
      />
      <label>{label}</label>
      <label>{supportedFileTypes}</label>
    </div>
  )
}