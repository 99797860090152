import { useEffect, useState } from 'react'

const useFocusedInput = () => {
  const [active, setActive] = useState<any>(undefined)

  const handleFocusIn = (e: any) => {
    setTimeout(() => {
      setActive(e.target)
    }, 200)
  }
  const handleFocusOut = () => {
    setTimeout(() => {
      setActive(undefined)
    }, 200)
  }

  useEffect(() => {
    document.addEventListener('focusin', handleFocusIn)
    document.addEventListener('focusout', handleFocusOut)
    return () => {
      document.removeEventListener('focusin', handleFocusIn)
      document.addEventListener('focusout', handleFocusOut)
    }
  }, [])

  return active
}

export default useFocusedInput
