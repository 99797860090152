import { t } from 'i18next'

export const generateError = (error: any) => {
  let generatedErr = t('ERRORS.SOMETHING_WENT_WRONG')
  if (typeof error === 'string' && error != 'undefined') {
    switch(error) {
    case 'USER_NOT_FOUND':
    case 'GET_USER_PROFILE':
    case 'DELETE_USER_PROFILE':
    case 'ENSURE_TOKEN':
    case 'CREATE_SERVICE_REQUEST':
    case 'GET_SERVICE_REQUEST':
    case 'GET_CONTRACTS':
    case 'CREATE_AMENDMENT':
    case 'GET_SERVICE_REQUEST_CATEGORIES':
    case 'SEND_ATTACHMENT':
    case 'GET_ATTACHMENT':
    case 'GET_ADDRESS':
    case 'INVALID_CONTRACT':
    case 'VERIFY_CONTRACT':
    case 'MANUAL_CONTRACT_VALIDATION':
    case 'TOKEN_GRAPH_API':
      generatedErr = t(`ERRORS.${error}`)
      break
    default:
      generatedErr = error 
    }
  }
  return generatedErr
}
