import { CreateAmendmentDto, CreateAmendmentEntity } from '../../entities'
import { api } from '../../services/api.service'

class AmendmentApi {
  async createAmendment(amendment: CreateAmendmentDto, attachments: File[]): Promise<CreateAmendmentEntity> {
    const apiCall = await api.post('/api/amendment', {...amendment, files: attachments}, {
      timeout: 500000,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return apiCall.data
  }
}

export const amendmentApi = new AmendmentApi()
