import React from 'react'
import Select from 'react-select'
import './index.scss'

export const CustomSelect = ({
  label,
  value,
  onChange,
  name,
  placeholder,
  options,
  error,
  onInputChange,
  loading
}: {
  label: string,
  value: { value: string, label: string },
  onChange: any,
  onInputChange?: any,
  name: string,
  type: string,
  placeholder: string,
  options: { value: string, label: string }[],
  error?: string,
  loading?: boolean
}) => {
  return (
    <div className='custom-input'>
      <label>{label}</label>
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: '#dfdfdf!important',
            outline: 'none',
            borderRadius: '0ox',
            padding: '7px 10px',
            boxShadow: 'none'
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#a1a1a1'
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            color: '#212529',
            backgroundColor: '#fff',
            ':hover': {
              backgroundColor: '#eee'
            },
            ':active': {
              backgroundColor: '#eee'
            }
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#212529'
          }),
        }}
        defaultInputValue=''
        name={name}
        value={value.value ? value : ''}
        onInputChange={onInputChange}
        onChange={(e) => {
          onChange(name, typeof e === 'object' ? e?.value : '')
        }}
        placeholder={placeholder}
        options={options}
        noOptionsMessage={() => !loading ? (
          'No options'
        ) : (
          <div className='custom-select-loading-holder'>
            <div className="loader"></div>
          </div>
        )} 
      />
      {error && <p className='error'>{error}</p>}
    </div>
  )
}