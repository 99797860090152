import {
  UserIcon,
  PowerIcon,
  Cog6ToothIcon,
  HomeIcon,
  ClipboardDocumentListIcon
} from '@heroicons/react/24/outline'


export const menuItems: {path: string, svg: any, name: string, subpath: string[], submenu: {name: string, path: string}[]}[] = [
  {
    path: '/',
    svg: HomeIcon,
    name: 'HOME',
    subpath: [],
    submenu: []
  },
  {
    path: '/contracts',
    svg: ClipboardDocumentListIcon,
    name: 'CONTRACTS',
    subpath: ['/contracts/add-contract'],
    submenu: []
  },
  {
    path: '/repairs-and-inquiries',
    svg: Cog6ToothIcon,
    name: 'REPAIRS_AND_INQUIRIES',
    subpath: ['/repairs-and-inquiries/new-request'],
    submenu: []
  },
  {
    path: '/account',
    name: 'ACCOUNT',
    svg: UserIcon,
    subpath: [
      '/account/edit-email',
      '/account/edit-password',
      '/account/delete-account'
    ],
    submenu: []
  },
  {
    path: '#',
    name: 'LOG_OUT',
    svg: PowerIcon,
    subpath: [],
    submenu: []
  }
]
