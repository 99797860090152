import React from 'react'
import { RepairAndInquiriesStatusEntity } from '../../../../customEntites/RepairAndInquiries.entity'
import { GetServiceRequestEntity } from '../../../../entities'
import { RequestSummary } from './RequestSummary'

export const RequestsList = ({
  requests,
  isMobile,
  activeItem,
  setActiveItem,
  setShowAmendForm,
  showAmendForm,
  statuses,
  setRequests
}: {
  requests: GetServiceRequestEntity[],
  isMobile: boolean,
  activeItem: GetServiceRequestEntity,
  setActiveItem: any,
  setShowAmendForm: any,
  showAmendForm: boolean,
  statuses: RepairAndInquiriesStatusEntity[],
  setRequests: any
}) => {
  return (
    <div>
      {requests?.map((request) => (
        <RequestSummary
          requests={requests}
          statuses={statuses}
          key={request?.code}
          request={request}
          isMobile={isMobile}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          showAmendForm={showAmendForm}
          setShowAmendForm={setShowAmendForm}
          setRequests={setRequests}
        />
      ))}
    </div>
  )
} 
