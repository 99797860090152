import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { RepairAndInquiriesStatusEntity } from '../../../../customEntites/RepairAndInquiries.entity'
import useCurrentWidth from '../../../../customHooks/resizeWindowHook'
import { GetServiceRequestEntity } from '../../../../entities'
import { RequestsList, RequestContent } from './'
import { useNavigate } from 'react-router-dom'
import { FilterByStatus } from './FilterByStatus'

export const Multipanel = ({
  requests,
  activeItem,
  setActiveItem,
  activeStatus,
  setActiveStatus,
  setRequests
}: {
  requests: GetServiceRequestEntity[],
  activeItem: GetServiceRequestEntity,
  setActiveItem: any,
  activeStatus: number,
  setActiveStatus: any,
  setRequests: any
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showAmendForm, setShowAmendForm] = useState(false)
  const isMobile = useCurrentWidth()

  const statuses: RepairAndInquiriesStatusEntity[] = [
    {
      id: 1,
      name: 'Open',
      nameDe: 'Offen',
      nameAims: ['Check by SC', 'New', 'Advanced Check', 'Open']
    },
    {
      id: 2,
      name: 'In progress',
      nameDe: 'In Bearbeitung',
      nameAims: ['In Process', 'In process']
    },
    {
      id: 3,
      name: 'Completed',
      nameDe: 'Fertig gestellt',
      nameAims: ['Cancelled', 'Closed', 'Completed']
    },
  ]

  return (
    <div className='requests-multipanel-wrapper'>
      <FilterByStatus
        statuses={statuses}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
      />
      <div className='new-request-btn-holder'>
        <Button
          className='main-btn'
          size='large'
          variant='contained'
          onClick={() => navigate('/repairs-and-inquiries/new-request')}
        >
          {t('REPAIR_AND_INQUIRIES.CREATE_REQUEST')}
        </Button>
      </div>
      {requests?.length === 0 && activeStatus !== 0 && (
        <h4 className='no-requests-filter-text'>{t('REPAIR_AND_INQUIRIES.CURRENTLY_NO_REQUESTS')}</h4>
      )}
      <div className='multipanel-main'>
        <RequestsList
          statuses={statuses}
          requests={requests}
          isMobile={isMobile}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          setShowAmendForm={setShowAmendForm}
          showAmendForm={showAmendForm}
          setRequests={setRequests}
        />
      </div>
      {!isMobile && activeItem && (
        <div className='multipanel-sidebar'>
          <RequestContent
            statuses={statuses}
            showAmendForm={showAmendForm}
            setShowAmendForm={setShowAmendForm}
            activeItem={activeItem}
            setRequests={setRequests}
            requests={requests}
          />
        </div>
      )}
    </div>
  )
}
