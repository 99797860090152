import React from 'react'
import { Link } from 'react-router-dom'
import BackIcon from '../../../../../assets/icons/back'
import './index.scss'

export const Header = ({redirectLink, heading}: {redirectLink: string, heading: string}) => {

  return (
    <header>
      <div className='header-content-wrapper wrapper'>
        <Link className='back' to={redirectLink}>
          <BackIcon />
        </Link>
        <h1>{heading}</h1>
      </div>
    </header>
  )
}
