import { LogLevel } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZ_CLIENT_ID!,
    authority: process.env.REACT_APP_AZ_AUTHORITY!,
    redirectUri: process.env.REACT_APP_AZ_REDIRECT_URL!,
    postLogoutRedirectUri: process.env.REACT_APP_AZ_POST_LOGOUT_REDIRECT_URL!,
    clientCapabilities: [process.env.REACT_APP_AZ_CLIENT_CAPABILITIES!],
    knownAuthorities: [process.env.REACT_APP_AZ_KNOWN_AUTHORITIES!],
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return
        }
        switch (level) {
        case LogLevel.Error:
          return
        case LogLevel.Info:
          return
        case LogLevel.Verbose:
          return
        case LogLevel.Warning:
          return
        }
      },
    },
  },
}

export const b2cPolicies = {
  names: {
    editProfile: 'B2C_1_edit',
    changePassword: 'B2C_1_passwordreset'
  },
  authorities: {
    editProfile: {
      authority: process.env.REACT_APP_AZ_EDIT_PROFILE_AUTHORITY,
      scopes: []
    },
    changePassword: {
      authority: process.env.REACT_APP_AZ_CHANGE_PASSWORD_AUTHORITY,
      scopes: []
    },
    changeEmail: {
      authority: process.env.REACT_APP_AZ_CHANGE_EMAIL_AUTHORITY,
      scopes: []
    }
  },
  authorityDomain: 'auth-uat.aroundtown.de',
}

export const appRoles = {
  TaskUser: 'TaskUser',
  TaskAdmin: 'TaskAdmin'
}
